import React, { useState, useEffect } from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import { BsFillPencilFill, BsPlusLg } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import Loadimg from './loader';
import axios from 'axios';

const REACT_APP_ProjectTechImage = process.env.REACT_APP_PRJECTTECHIMAGE;

const ProjectTechImage = () => {
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [buttonloader, setButtonLoader] = useState(false);
    const [ProjectTechImagedata, setProjectTechImageData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedImageId, setSelectedImageId] = useState(null);
    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        s_no: '',
        link: ''
    });

    const fetchData = async () => {
        try {
            const res = await axios.get(`${REACT_APP_ProjectTechImage}all`);
            setProjectTechImageData(res.data);
            setLoading(false);
        } catch (error) {
            console.log("Error fetching data:", error);
            setLoading(false);
        }
    };

    const getNextSerialNumber = () => {
        const existingSerialNumbers = ProjectTechImagedata.map(item => item.s_no);
        const maxSerialNumber = Math.max(...existingSerialNumbers);
        return maxSerialNumber !== -Infinity ? maxSerialNumber + 1 : 1;
    };

    useEffect(() => {
        if (!loading) {
            const nextSerialNumber = getNextSerialNumber();
            setFormData(prevState => ({
                ...prevState,
                s_no: nextSerialNumber
            }));
        }
    }, [loading]);

    useEffect(() => {
        fetchData();
    }, []);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleCreate = async () => {
        let hasError = false;
        const newErrors = {};

        ['s_no', 'title',].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
            if (!selectedFile) {
                newErrors['techimage'] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }
        setButtonLoader(true);
        const form = new FormData();

        form.append('s_no', formData.s_no);
        form.append('techimage', selectedFile);
        form.append('title', formData.title);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.post(`${REACT_APP_ProjectTechImage}`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${refreshToken}`
                },
            });
            if (response.status === 201) {
                Swal.fire({
                    title: "ProjectTechImage image has been successfully saved",
                    icon: "success",
                    timer: 1500
                });
                setFormData({ s_no: '', title: '', selectedFile: null });
                setShowModal(false);
                fetchData(); // Update blog data after successful creation
                window.location.reload()
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to save ProjectTechImage Image.Try again!",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error",
                timer: 1500
            });
        } finally {
            setButtonLoader(false); // Hide BeatLoader
        }
    };

    const handleEditing = (id) => {
        const selectedData = ProjectTechImagedata.find(item => item._id === id);
        setSelectedImageId(selectedData);
        setFormData(prevState => ({
            ...prevState,
            s_no: selectedData.s_no,
            title: selectedData.title,
            techimage: selectedData.techimage
        }))
        setEditModal(true);
    };

    const handleEdit = async () => {
        let hasError = false;
        const newErrors = {};

        ['s_no', 'title'].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }
        setButtonLoader(true);
        const form = new FormData();

        form.append('s_no', formData.s_no);
        form.append('techimage', selectedFile);
        form.append('title', formData.title);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_ProjectTechImage}${selectedImageId._id}`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${refreshToken}`
                },
            });
            if (response.status === 200) {
                Swal.fire({
                    title: "ProjectTechImage image has been successfully modified",
                    icon: "success",
                    timer: 1500
                });
                setFormData({ s_no: '', link: '', selectedFile: null });
                setEditModal(false);
                fetchData();
                window.location.reload()
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to Update image.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error",
                timer: 1500
            });
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    };

    const handleDelete = (id) => {
        confirmDelete(id);
    };

    const confirmDelete = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCloseButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
            timer: 1500
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const refreshToken = Cookies.get('refreshToken');
                    const response = await axios.delete(`${REACT_APP_ProjectTechImage}${id}`, {
                        headers: {
                            'Authorization': `Bearer ${refreshToken}`
                        }
                    });
                    if (response.status === 200) {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success",
                            timer: 1500
                        });
                        fetchData();
                        window.location.reload()
                    }
                } catch (error) {
                    console.error('Error While deleteing:', error);
                    Swal.fire({
                        title: "Error!",
                        text: error.response?.data?.message || error.message || "An error occurred",
                        icon: "error",
                        timer: 1500
                    });
                }
            }
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const resetForm = () => {
        const nextSerialNumber = getNextSerialNumber();
        setFormData({ s_no: nextSerialNumber, title: '' });
        setSelectedFile(null);
        setErrors({});
    };

    return (
        <>
            {loading ? (
                <Loadimg
                />
            ) : (
                <>
                    {/* //-------------------------- Add New Post button--------------------// */}
                    <div className="relative pb-[100px]">
                        <div type="btn" onClick={() => setShowModal(true)} className="absolute cursor-pointer hover:shadow-inner shadow-sm btn-secondary flex text-white right-0 mt-[15px] gap-2"><span className=''><BsPlusLg /></span>Add Project Tech Image
                        </div>
                    </div>
                    {/* //-------------------Add New Post button end here----------------// */}
                    {/* //------Adding mape method for geting all data form backend--------// */}
                    <section className="w-full text-gray-500 dark:text-gray-400">
                        <div className="grid grid-cols-3 lg:grid-cols-4 2xl:grid-cols-4 relative gap-4">
                            {ProjectTechImagedata.map((item) => (
                                <div key={item._id} className="flex group cursor-pointer justify-center text-center" data-aos="zoom-in" data-aos-duration="1000">
                                    <div className="group relative overflow-hidden p-4">
                                        <img src={item.techimage} className="rounded-md h-full w-full" alt="" />
                                        <button type="button" onClick={() => handleEditing(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="font-medium z-10 flex absolute bg-[white] rounded-full p-2 top-5 right-5 text-blue-600 dark:text-blue-500 transition-all duration-300 hover:scale-125 hover:underline">
                                            <BsFillPencilFill />
                                        </button>
                                        <div className="absolute grid overflow-hidden inset-x-0 justify-items-center -bottom-full pb-3 transition-all duration-300 group-hover:bottom-0">
                                            <p className='text-md font-bold'>{item.title}</p>
                                            <button type="button" onClick={() => handleDelete(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="flex font-medium transition-all duration-300 bottom-3 text-[black] bg-[white] rounded-full p-2 hover:scale-125 hover:underline">
                                                <FaTrash />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                    {/* //------mape method for geting all data form backend end Here--------// */}
                </>
            )}

            {
                showModal && (
                    <div className="justify-center items-center bg-[#00000047] shadow-xl flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 rounded-t">
                                    <h4 className='font-bold text-secondary-300 dark:text-secondary-400 text-4xl'>
                                        Create Project Tech Image
                                    </h4>
                                    <button className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setShowModal(false); resetForm(); }}>
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative px-6 pb-6 flex-auto">
                                    <div className="grid grid-cols-2 gap-2">
                                        <div className="flex items-end">
                                            <div className="div">
                                                <label className={`block text-sm font-semibold mb-2 ${errors.image ? 'text-red-500' : 'text-white'}`} >
                                                    Project Tech Image*
                                                </label>
                                                <input className={`block w-full p-0.5 mb-0.5 text-gray-900 border-2 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none  dark:border-gray-600 dark:placeholder-gray-400  ${errors.image ? 'border-red-500' : 'border-gray-300'}`} type="file" accept="image/*" onChange={handleFileChange} required />
                                            </div>
                                        </div>
                                        <div className="div">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`} >
                                                Serial No.*
                                            </label>
                                            <input
                                                className={`shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight border-2 focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`}
                                                type="number"
                                                id="s_no"
                                                value={formData.s_no}
                                                placeholder='Serial no'
                                                onChange={(e) => setFormData({ ...formData, s_no: e.target.value })}
                                            />
                                        </div>
                                        <div className="div">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.link ? 'text-red-500' : 'text-white'}`} htmlFor="link">
                                                Title*
                                            </label>
                                            <textarea
                                                className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title ? 'border-red-500' : 'border-gray-300'}`}
                                                id="link"
                                                type="text"
                                                value={formData.title}
                                                placeholder="Add Your Tech. Title"
                                                onChange={(e) => setFormData({ ...formData, title: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <button onClick={() => { setButtonLoader(true); handleCreate() }} className="btn-submit btn text-center text-white p-5 rounded">
                                            {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                selectedImageId && editModal ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 rounded-t">
                                    <h4 className='font-bold text-secondary-300 dark:text-secondary-400 text-4xl'>
                                        Edit
                                    </h4>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setEditModal(false); resetForm(); }}
                                    >
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative px-6 pb-6 flex-auto">
                                    <div className="grid grid-cols-2 gap-2">
                                        <div className="div">
                                            <figure className='w-12 h-12 rounded-md overflow-hidden'>
                                                <img className='h-full w-full object-cover overflow-hidden' src={selectedImageId.techimage} alt=''></img>
                                            </figure>
                                            <label className='block text-sm font-semibold mb-2 text-white' >
                                                Project Tech Image*
                                            </label>
                                            <input className='block w-full p-0.5 mb-0.5 text-gray-900 border-2 rounded border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none  dark:border-gray-600 dark:placeholder-gray-400' type="file" accept="image/*" onChange={handleFileChange} required />
                                        </div>
                                        <div className="flex items-end">
                                            <div className="div w-full">
                                                <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`} >
                                                    Serial No.*
                                                </label>
                                                <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" id="s_no" value={formData.s_no} placeholder='Serial no' onChange={(e) => setFormData({ ...formData, s_no: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="div">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.title ? 'text-red-500' : 'text-white'}`} htmlFor="link">
                                                Title*
                                            </label>
                                            <input
                                                className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title ? 'border-red-500' : 'border-gray-300'}`} id="link" type="text" value={formData.title} placeholder="Add Your ProjectTechImage Link" onChange={(e) => setFormData({ ...formData, title: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <button onClick={() => { setButtonLoader(true); handleEdit() }} className="btn-submit btn text-center text-white p-5 rounded">
                                            {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </>
    );
};

export default ProjectTechImage;
