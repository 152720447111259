import React, { useState, useEffect } from 'react';
import { BsFillPencilFill, BsPlusLg } from "react-icons/bs";
import PulseLoader from "react-spinners/PulseLoader";
import { FaTrash } from "react-icons/fa";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import Loadimg from './loader';
import axios from 'axios';

const REACT_APP_FAQ = process.env.REACT_APP_FAQ;

const FAQs = () => {
    const [showModal, setShowModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [buttonloader, setButtonLoader] = useState(false);
    const [faqs, setFAQs] = useState([]);
    const [selectedFAQId, setSelectedFAQId] = useState(null);
    const [errors, setErrors] = useState({});
    const [showAnswerStates, setShowAnswerStates] = useState(Array(faqs.length).fill(false));

    const handleToggleAnswer = (index) => {
        const newShowAnswerStates = [...showAnswerStates];
        newShowAnswerStates[index] = !newShowAnswerStates[index];
        setShowAnswerStates(newShowAnswerStates);
    };

    const [formData, setFormData] = useState({
        s_no: '',
        question: '',
        answer: ''
    });

    const resetForm = () => {
        const nextSerialNumber = getNextSerialNumber();
        setFormData({ s_no: nextSerialNumber, question: '', answer: '' });
        setErrors({});
    };

    const fetchData = async () => {
        try {
            const res = await axios.get(`${REACT_APP_FAQ}all`);
            setFAQs(res.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const getNextSerialNumber = () => {
        const existingSerialNumbers = faqs.map(item => item.s_no);
        const maxSerialNumber = Math.max(...existingSerialNumbers);
        return maxSerialNumber !== -Infinity ? maxSerialNumber + 1 : 1;
    };

    // Function to reset form data
    const handleInputChange = (e) => {
        const { value } = e.target;
        setFormData({ ...formData, value });
    };

    useEffect(() => {
        if (!loading) {
            const nextSerialNumber = getNextSerialNumber();
            setFormData(prevState => ({
                ...prevState,
                s_no: nextSerialNumber
            }));
        }
    }, [loading]);

    const handleSubmit = async () => {
        let hasError = false;
        const newErrors = {};
        ['s_no', 'question', 'answer'].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }
        setButtonLoader(true); // Show BeatLoader

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.post(`${REACT_APP_FAQ}`, formData, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (response.status === 200) {
                Swal.fire({
                    title: "FAQ's Added successfully.",
                    icon: "success",
                    timer: 1500
                });
                setFormData({ question: '', answer: '' });
                setShowModal(false);
                fetchData();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh!Failed to Add Faq.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false); // Hide BeatLoader
        }
    };

    const handleEditing = (id) => {
        const selectedData = faqs.find(item => item._id === id);
        setSelectedFAQId(selectedData); // Set the selected FAQ ID
        setFormData(prevState => ({
            ...prevState,
            s_no: selectedData.s_no,
            question: selectedData.question,
            answer: selectedData.answer
        }));
        setEditModal(true)
    }

    const handleEdit = async () => {
        let hasError = false;
        const newErrors = {};
        ['s_no', 'question', 'answer'].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }
        setButtonLoader(true); // Show BeatLoader

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_FAQ}${selectedFAQId._id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (response.status === 200) {
                Swal.fire({
                    title: "FAQ updated successfully.",
                    icon: "success"
                });
                setFormData({ s_no: '', question: '', answer: '' });
                setEditModal(false);
                fetchData();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to Update Faqs.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error",
                timer: 1500
            });
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    }

    const handleDelete = (id) => {
        confirmDelete(id);
    };

    const confirmDelete = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            timer: 1500
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const refreshToken = Cookies.get('refreshToken');
                    const response = await axios.delete(`${REACT_APP_FAQ}${id}`, {
                        headers: {
                            'Authorization': `Bearer ${refreshToken}`
                        }
                    });
                    if (response.status === 200) {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success",
                            timer: 1500
                        });
                        fetchData();
                        window.location.reload()
                    }
                } catch (error) {
                    console.error('Error While deleteing:', error);
                    Swal.fire({
                        title: "Error!",
                        text: error.response?.data?.message || error.message || "An error occurred",
                        icon: "error",
                        timer: 1500
                    });
                }
            }
        })
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div>
                {loading ? (
                    <Loadimg
                    />
                ) : (
                    <>
                        {/* //-------------------------- Add New Post button--------------------// */}
                        <div className="relative pb-[100px]">
                            <div type="btn" onClick={() => setShowModal(true)} className="absolute cursor-pointer hover:shadow-inner shadow-sm btn-secondary flex text-white right-0 mt-[15px] gap-2"><span><BsPlusLg /></span>Add FAQ's
                            </div>
                        </div>
                        {/* //-------------------Add New Post button end here----------------// */}
                        <section className='faqs-sec'>
                            <div className='grid gap-8 grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 relative'>
                                {faqs.map((item, index) => (
                                    <div key={item._id} className="group p-4 cursor-pointer border shadow-md overflow-hidden rounded-3xl transition delay-700 duration-300 ease-in-out dark:text-gray-400 dark:border-secondary-50" data-aos="zoom-in" data-aos-duration="1000">
                                        <div className='w-2 h-2'>
                                            <button type='button' onClick={() => handleEditing(item._id)} className="font-medium z-10 flex absolute bg-[white] rounded-full p-2 top-5 right-5 text-blue-600 transition delay-700 duration-300 ease-in-out dark:text-blue-500 hover:scale-125 hover:underline">
                                                <BsFillPencilFill />
                                            </button>
                                        </div>
                                        <div className='pb-3' onClick={() => handleToggleAnswer(index)}>
                                            <p className='font-semibold text-xl'>{item.question}</p>
                                        </div>
                                        {showAnswerStates[index] && (
                                            <div className=''>
                                                <p>Ans:- {item.answer}</p>
                                            </div>
                                        )}
                                        <ul className="absolute inset-x-0 -bottom-full flex items-center justify-center gap-12 pb-2 transition-all duration-300 group-hover:-inset-2 group-hover:bg-gradient-to-t group-hover:from-white/70">
                                            <li className='w-2 h-2'>
                                                <button type="button" onClick={() => handleDelete(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="flex absolute font-medium transition-all duration-300 bottom-6 text-[black] bg-[white] rounded-full p-2 hover:scale-125 hover:underline">
                                                    <FaTrash />
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </section>
                        {/* //------Adding mape method for geting all data form backend--------// */}
                    </>
                )}
            </div>
            {
                showModal ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none p-5">
                                {/*header*/}
                                <div className="flex items-start justify-between rounded-t">
                                    <h4 className='pb-2 font-bold text-secondary-500 text-4xl'>Add FAQ's</h4>
                                    <button className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setShowModal(false); resetForm(); }}>
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto">
                                    <div className="div">
                                        <label lassName={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                            Serial No*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" value={formData.s_no} onChange={(e) => setFormData({ ...formData, s_no: e.target.value })} required />
                                    </div>
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.question ? 'text-red-500' : 'text-white'}`} htmlFor="order">
                                            Question*
                                        </label>
                                        <textarea className={`shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.question ? 'border-red-500' : 'border-gray-300'}`} type="text" name="question" value={formData.question} onChange={(e) => setFormData({ ...formData, question: e.target.value })} placeholder='Question' required />
                                    </div>
                                    <div className="div">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.answer ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                            Answer*
                                        </label>
                                        <textarea className={`shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.answer ? 'border-red-500' : 'border-gray-300'}`} type="text" name="answer" value={formData.answer} onChange={(e) => setFormData({ ...formData, answer: e.target.value })} placeholder='Answer' required />
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <button onClick={() => { setButtonLoader(true); handleSubmit() }} className="btn-submit btn text-center text-white p-5 rounded">
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
            {selectedFAQId && editModal ? (
                <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                    <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none p-5">
                            {/*header*/}
                            <div className="flex items-start justify-between rounded-t">
                                <h4 className='pb-2 font-bold text-secondary-500 text-4xl'>Edit</h4>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => { setEditModal(false); resetForm(); }}
                                >
                                    <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative flex-auto">
                                <div className="div">
                                    <label lassName={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                        Serial No*
                                    </label>
                                    <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" value={formData.s_no} onChange={(e) => setFormData({ ...formData, s_no: e.target.value })} required />
                                </div>
                                <div className="div">
                                    <label className={`block text-sm font-semibold mb-2 ${errors.question ? 'text-red-500' : 'text-white'}`} htmlFor="order">
                                        Question*
                                    </label>
                                    <textarea className={`shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.question ? 'border-red-500' : 'border-gray-300'}`} type="text" name="question" value={formData.question} onChange={(e) => setFormData({ ...formData, question: e.target.value })} placeholder='Question' required />
                                </div>
                                <div className="div">
                                    <label className={`block text-sm font-semibold mb-2 ${errors.answer ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                        Answer*
                                    </label>
                                    <textarea className={`shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${errors.answer ? 'border-red-500' : 'border-gray-300'}`} type="text" name="answer" value={formData.answer} onChange={(e) => setFormData({ ...formData, answer: e.target.value })} placeholder='Answer' required />
                                </div>
                            </div>
                            <div className=" flex justify-center">
                                <button onClick={() => { setButtonLoader(true); handleEdit() }} className="btn-submit btn text-center text-white rounded">
                                    {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null
            }
        </>
    )
};
export default FAQs;
