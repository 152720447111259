import React, { useState, useEffect } from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import { BsPlusLg, BsFillPencilFill } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import Loadimg from './loader';
import axios from 'axios';


const REACT_APP_BLOG = process.env.REACT_APP_BLOG;
const REACT_APP_CATEGORY_BLOG = process.env.REACT_APP_CATEGORY_BLOG;

const animatedComponents = makeAnimated();

const Blog = () => {
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [buttonloader, setButtonLoader] = useState(false);
    const [blogData, setBlogData] = useState([]);
    const [blogcatgarData, setBlogCatgarData] = useState([]);
    const [selectedBannerImage, setSelectedBannerImage] = useState(null);
    const [selectedBlog, setSelectedBlog] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalBlogCount, setTotalBlogCount] = useState(0);
    const [numField, setNumField] = useState(1);
    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        s_no: '',
        slug: '',
        title: '',
        blogdetail: '',
        category: [],
        bannerimg: null,
        headingdata: [{
            heading: '',
            detail: '',
            images: { image1: "", image2: "" }
        }]
    });

    const fetchData = async () => {
        try {
            const response = await axios.get(`${REACT_APP_BLOG}all?page=${currentPage}`);
            setBlogData(response.data.allBlog);
            setTotalBlogCount(response.data.totalBlogCount);
            setTotalPages(response.data.totalPages);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const fetchblogData = async () => {
        try {
            const response = await axios.get(`${REACT_APP_CATEGORY_BLOG}all`);
            setBlogCatgarData(response.data);
        } catch (error) {
            console.log('Error fetching Category:', error);
        }
    }

    const options = blogcatgarData.map(texts => ({
        value: texts.s_no,
        label: `${texts.name}`
    }))

    const getNextSerialNumber = () => {
        const existingSerialNumbers = blogData.map(item => item.s_no);
        const maxSerialNumber = Math.max(...existingSerialNumbers);
        return maxSerialNumber !== -Infinity ? maxSerialNumber + 1 : 1;
    };

    const handleInputChange = (e, index, type) => {
        const updatedFormData = { ...formData };
        if (!updatedFormData.headingdata[index]) {
            updatedFormData.headingdata[index] = { heading: '', detail: '', images: { image1: null, image2: null } };
        }

        if (type === 'heading') {
            updatedFormData.headingdata[index].heading = e.target.value;
        } else if (type === 'detail') {
            updatedFormData.headingdata[index].detail = e.target.value;
        }

        setFormData(updatedFormData);
    };

    const handleRemoveImage = (index, imageType) => {
        const newHeadingData = [...formData.headingdata];
        newHeadingData[index].images[imageType] = null;
        setFormData({ ...formData, headingdata: newHeadingData });
    };

    const handleEditExistingImage = (event, index, field) => {
        const newImage = event.target.files[0];
        const newHeadingData = [...formData.headingdata];
        newHeadingData[index].images[field] = newImage;
        setFormData({ ...formData, headingdata: newHeadingData });
    };

    const handleFileChange = (e, index, imageNumber) => {
        const file = e.target.files[0];
        const newHeadingData = [...formData.headingdata];
        if (imageNumber === 1) {
            newHeadingData[index].images.image1 = file;
        } else if (imageNumber === 2) {
            newHeadingData[index].images.image2 = file;
        }
        setFormData({ ...formData, headingdata: newHeadingData });
    };

    const handleAddField = () => {
        setNumField(numField + 1);
        setFormData(prevState => ({
            ...prevState,
            headingdata: [
                ...prevState.headingdata,
                { heading: '', detail: '', images: { image1: null, image2: null } }
            ]
        }));
    };

    const handleRemoveField = () => {
        if (formData.headingdata.length > 1) {
            setNumField(numField - 1);
            setFormData(prevState => ({
                ...prevState,
                headingdata: prevState.headingdata.slice(0, -1)
            }));
        }
    };

    const handleCreate = async () => {
        let hasError = false;
        const newErrors = {};
        const uploadData = new FormData();

        ['s_no', 'slug', 'title'].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (!formData.bannerimg) {
            newErrors['bannerimg'] = true;
            hasError = true;
        } else {
            uploadData.append('bannerimg', formData.bannerimg);
        }

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return;
        }

        setButtonLoader(true);

        uploadData.append('s_no', formData.s_no);
        uploadData.append('slug', formData.slug);
        uploadData.append('title', formData.title);
        uploadData.append('category', formData.category);
        uploadData.append('blogdetail', formData.blogdetail);

        formData.headingdata.forEach((entry, index) => {
            uploadData.append(`heading${index + 1}`, entry.heading);
            uploadData.append(`detail${index + 1}`, entry.detail);
            if (entry.images.image1) {
                uploadData.append(`image1_${index + 1}`, entry.images.image1);
            }
            if (entry.images.image2) {
                uploadData.append(`image2_${index + 1}`, entry.images.image2);
            }
        });

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.post(`${REACT_APP_BLOG}`, uploadData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${refreshToken}`
                }
            });

            if (response.status === 201) {
                Swal.fire({ title: "Blog successfully Added", icon: "success",timer: 1500 });
                resetForm();
                setShowModal(false);
                fetchData();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({ title: "Ohh! Failed to save blog. Try again!", text: error.response?.data?.message || error.message || "An error occurred", icon: "error" });
        } finally {
            setButtonLoader(false);
        }
    };

    const handleEdit = (id) => {
        const selectBlog = blogData.find(item => item._id === id);
        setSelectedBlog(selectBlog);
        setEditModal(true);
        setFormData({
            ...selectBlog,
            s_no: selectBlog.s_no,
            slug: selectBlog.slug,
            title: selectBlog.title,
            blogdetail: selectBlog.blogdetail,
            bannerimg: selectBlog.bannerimg,
            category: selectBlog.category || [],
            headingdata: selectBlog.headingdata.map(entry => ({
                heading: entry.heading,
                detail: entry.detail,
                images: {
                    image1: entry.images.image1 || '',
                    image2: entry.images.image2 || ''
                }
            }))
        });
    };

    const handleModifyBlog = async () => {
        setButtonLoader(true);
        const uploadData = new FormData();

        if (selectedBannerImage) {
            uploadData.append('bannerimg', selectedBannerImage);
        } else if (formData.bannerimg) {
            uploadData.append('bannerimg', formData.bannerimg);
        }

        uploadData.append('s_no', formData.s_no);
        uploadData.append('slug', formData.slug);
        uploadData.append('title', formData.title);
        uploadData.append('blogdetail', formData.blogdetail);
        uploadData.append('category', formData.category);

        formData.headingdata.forEach((entry, index) => {
            uploadData.append(`heading${index + 1}`, entry.heading);
            uploadData.append(`detail${index + 1}`, entry.detail);
            if (entry.images.image1) {
                uploadData.append(`image1_${index + 1}`, entry.images.image1);
            }
            if (entry.images.image2) {
                uploadData.append(`image2_${index + 1}`, entry.images.image2);
            }
        });

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_BLOG}${selectedBlog._id}`, uploadData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${refreshToken}`
                }

            });

            if (response.status === 200) {
                Swal.fire({ title: "Blog has been successfully Modified", icon: "success" });
                resetForm();
                setEditModal(false);
                fetchData();
                window.location.reload();
            }
        } catch (error) {
            console.error('Error modifying blog:', error);
            Swal.fire({ title: "Ohh! Failed to modify blog. Try again!", text: error.response?.data?.message || error.message || "An error occurred", icon: "error" ,timer: 1500});
        } finally {
            setButtonLoader(false);
        }
    };

    const handleDelete = (id) => {
        confirmDelete(id);
    };

    const confirmDelete = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCloseButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
            timer: 1500,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const refreshToken = Cookies.get('refreshToken');
                    const response = await axios.delete(`${REACT_APP_BLOG}${id}`, {
                        headers: { 'Authorization': `Bearer ${refreshToken}` }
                    });

                    if (response.status === 200) {
                        Swal.fire({ title: "Deleted!", text: "Your file has been deleted.", icon: "success" });
                        fetchData();
                        window.location.reload();
                    }
                } catch (error) {
                    console.error('Error deleting blog:', error);
                    Swal.fire({ title: "Error!", text: error.response?.data?.message || error.message || "An error occurred", icon: "error",timer: 1500 });
                }
            }
        });
    };

    const handlePageChange = (page) => {
        const nextPage = Math.max(1, page);
        setCurrentPage(nextPage);
    };

    const renderPagination = () => {
        const pages = [];

        // Check if there are more than one page and the current page is not 1
        if (totalPages > 1 && !(totalPages === totalBlogCount && totalPages === currentPage)) {
            // Render the button for page 1
            pages.push(
                <button key={1} onClick={() => handlePageChange(1)} className={currentPage === 1 ? 'active' : 'pagination'}>
                    1
                </button>
            );
        }

        // Render buttons for other pages
        for (let i = 2; i <= totalPages; i++) {
            pages.push(
                <button key={i} onClick={() => handlePageChange(i)} className={currentPage === i ? 'active' : 'pagination'}>
                    {i}
                </button>
            );
        }

        return pages;
    };

    const resetForm = () => {
        const nextSerialNumber = getNextSerialNumber();
        setFormData({
            s_no: nextSerialNumber,
            slug: '',
            title: '',
            blogdetail: '',
            bannerimg: null,
            category: [],
            headingdata: [{
                heading: '',
                detail: '',
                images: { image1: null, image2: null }
            }]
        });
        setSelectedBannerImage(null);
        setNumField(1);
        setErrors({});
    };

    useEffect(() => {
        fetchData();
        fetchblogData();
        getNextSerialNumber();
        resetForm();
    }, [currentPage]);

    useEffect(() => {
        if (!loading) {
            const nextSerialNumber = getNextSerialNumber();
            setFormData(prevState => ({
                ...prevState,
                s_no: nextSerialNumber
            }));
        }
    }, [loading]);

    return (
        <>
            {loading ? (
                <Loadimg
                />
            ) : (
                <>
                    {/* Add New Blog button */}
                    <div className="relative pb-[100px]">
                        <div type="btn" onClick={() => setShowModal(true)} className="absolute cursor-pointer shadow-xl btn-secondary flex text-white right-0 mt-[15px] gap-2"><span><BsPlusLg /></span>Add Blog</div>
                    </div>
                    {/* Add New Blog button end here */}
                    {/* Mape method for getting all data from the backend */}
                    <section className='blog-sec'>
                        <div className='grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-6 transition duration-500'>
                            {blogData.map((item) => (
                                <div className="group relative rounded-3xl cursor-pointer overflow-hidden border border-white dark:border-secondary-400 bg-white transition duration-500 dark:bg-gray-dark hover:shadow-md hover:scale-110" data-aos="zoom-in" data-aos-duration="1000" key={item._id}>
                                    <div className='w-2 h-2 absolute -right-2 -top-3'>
                                        <button type='button' className="font-medium z-10 flex absolute bg-[white] rounded-full p-2 top-5 right-5 text-blue-600 transition-all duration-300 dark:text-blue-500 hover:scale-125 hover:underline" onClick={() => handleEdit(item._id)}>
                                            <BsFillPencilFill />
                                        </button>
                                    </div>
                                    <figure className="h-full max-h-[191px] overflow-hidden">
                                        <img src={item.bannerimg} className="w-full h-full object-cover" alt="" />
                                    </figure>
                                    <div className='p-5 text-sm font-bold text-left'>
                                        <h2 className='my-[10px] block text-lg font-extrabold leading-[23px] !text-current line-clamp-2'>{item.title}</h2>
                                    </div>
                                    <ul className="absolute inset-x-0 -bottom-full flex items-center justify-center gap-10 pb-5 transition-all duration-300 group-hover:inset-y-0 group-hover:bg-gradient-to-t group-hover:from-white/70">
                                        <li className='w-2 h-2'>
                                            <button type="button" onClick={() => handleDelete(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="flex absolute font-medium transition-all duration-300 bottom-6 text-[black] bg-[white] rounded-full p-2 hover:scale-125 hover:underline">
                                                <FaTrash />
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </section>
                    <div className="Pageination pt-4">
                        <div className=' flex gap-2 justify-center text-white '>{renderPagination()}</div>
                    </div>
                </>
            )}
            {showModal ? (
                <div className="justify-center items-center bg-[#000000a8] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50" data-aos="zoom-out" data-aos-duration="500">
                    <div className="relative rounded-lg overflow-hidden sm:w-[90%] md:w-[90%] lg:max-w-2xl xl:lg:max-w-2xl md:max-w-2xl sm:max-w-2xl">
                        {/*content*/}
                        <div className="border-0 shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between rounded-t p-5">
                                <h1 className='pb-2 font-bold text-secondary-500 text-4xl'>
                                    Add Blog
                                </h1>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => { setShowModal(false); resetForm(); }}
                                >
                                    <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative px-6 pb-6 flex-auto h-[38rem] overflow-y-scroll overflow-hidden">
                                <div className='grid gap-5 sm:grid-cols-2 lg:grid-cols-2'>
                                    <div className="div">
                                        <label className={`block text-semi-bold mb-1 ${errors.bannerimg ? 'text-red-500' : 'text-white'}`}>
                                            Cover / banner Image*
                                        </label>
                                        <input className={`block p-0.5 mb-0.5 w-full text-gray-900 border-2 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:placeholder-gray-400 ${errors.bannerimg ? 'border-red-500' : 'border-gray-300'} `} type="file" accept="image/*" onChange={(e) => setFormData({ ...formData, bannerimg: e.target.files[0] })} />
                                    </div>
                                    <div className="div">
                                        <label className="block text-sm font-semibold mb-2">Select blog category</label>
                                        <Select
                                            value={options.value}
                                            className="text-[black]"
                                            onChange={(selectedOptions) => setFormData({ ...formData, category: selectedOptions.map(option => option.value) })}
                                            components={animatedComponents}
                                            options={options}
                                            isMulti
                                            placeholder="Select Technology Images"
                                        />
                                    </div>
                                    <div className="">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}  >
                                            Serial No.*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`}
                                            type="number"
                                            id="s_no"
                                            value={formData.s_no}
                                            placeholder='Serial no'
                                            onChange={(e) => setFormData({ ...formData, s_no: e.target.value })}
                                        />
                                    </div>
                                    <div className="">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.slug ? 'text-red-500' : 'text-white'}`} >
                                            Slug*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.slug ? 'border-red-500' : 'border-gray-300'}`}
                                            type="text"
                                            id="slug"
                                            value={formData.slug}
                                            placeholder='https://codebeans.net/blog/"Slug"'
                                            onChange={(e) => setFormData({ ...formData, slug: e.target.value })}
                                        />
                                    </div>
                                    <div className="">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.title ? 'text-red-500' : 'text-white'}`} >
                                            Title*
                                        </label>
                                        <textarea className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title ? 'border-red-500' : 'border-gray-300'}`}
                                            type="text"
                                            id="title"
                                            value={formData.title}
                                            placeholder='Title'
                                            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                        />
                                    </div>
                                    <div className="">
                                        <label className="block !text-current text-sm font-normal mb-2" htmlFor="service-description" >
                                            Blog Description
                                        </label>
                                        <textarea
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="blogdetail"
                                            value={formData.blogdetail}
                                            placeholder='Blog Description'
                                            onChange={(e) => setFormData({ ...formData, blogdetail: e.target.value })}
                                        />
                                    </div>
                                    {[...Array(numField)].map((_, index) => (
                                        <React.Fragment key={index}>
                                            <div className="">
                                                <label htmlFor="service-sub-heading" className="block !text-current text-sm font-normal mb-2">
                                                    Heading {index + 1}
                                                </label>
                                                <textarea
                                                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                    value={formData.headingdata[index]?.heading || ''}
                                                    placeholder='Heading'
                                                    onChange={(e) => handleInputChange(e, index, 'heading')}
                                                />
                                            </div>
                                            <div className="">
                                                <label htmlFor="service-sub-description" className="block !text-current text-sm font-normal mb-2">
                                                    Description {index + 1}
                                                </label>
                                                <textarea
                                                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                    value={formData.headingdata[index]?.detail || ''}
                                                    placeholder='Description'
                                                    onChange={(e) => handleInputChange(e, index, 'detail')}
                                                />
                                            </div>
                                            <div className=''>
                                                <label className="block !text-current text-semi-bold mb-1" >
                                                    First Blog Image {index + 1}
                                                </label>
                                                <input className="block p-0.5 w-full text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50  focus:outline-none dark:border-gray-600 dark:placeholder-gray-400"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => handleFileChange(e, index, 1)}
                                                />
                                            </div>
                                            <div className=''>
                                                <label className="block !text-current text-semi-bold mb-1" >
                                                    Second Blog Image {index + 1}
                                                </label>
                                                <input className="block p-0.5 w-full text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50  focus:outline-none dark:border-gray-600 dark:placeholder-gray-400"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => handleFileChange(e, index, 2)}
                                                />
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div className="mt-5 flex gap-3 justify-center">
                                    <button
                                        className="btn-primary flex"
                                        onClick={handleAddField}><span>Add Field</span>
                                    </button>
                                    <button
                                        className="btn-secondary flex"
                                        onClick={handleRemoveField}><span className='!text-current'>Remove Field</span>
                                    </button>
                                </div>
                                <div className="flex justify-center">
                                    <button className="btn-submit btn text-center text-white p-5 rounded mt-5" onClick={() => { setButtonLoader(true); handleCreate() }} >
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            ) : null}

            {selectedBlog && editModal ? (
                <div className="justify-center items-center bg-[#000000a8] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50" data-aos="zoom-out" data-aos-duration="500">
                    <div className="relative rounded-lg overflow-hidden sm:w-[90%] md:w-[90%] lg:max-w-2xl xl:lg:max-w-2xl md:max-w-2xl sm:max-w-2xl">
                        {/*content*/}
                        <div className="border-0 shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between rounded-t p-5">
                                <h4 className='pb-2 font-bold text-secondary-500 text-4xl'>
                                    Customize Blog
                                </h4>
                                <button className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => { setEditModal(false); resetForm(); }} >
                                    <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">×</span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative px-6 pb-6 flex-auto h-[38rem] overflow-y-scroll overflow-hidden">
                                <div className='grid' >
                                    <div className='grid gap-5 sm:grid-cols-2 lg:grid-cols-2'>
                                        <div className="">
                                            <label className={`block !text-current text-semi-bold mb-1  ${errors.bannerimg ? 'text-red-500' : 'text-white'}`} >
                                                Cover / banner Image*
                                            </label>
                                            <div className='max-w-12 max-h-12 mb-2'>
                                                <img src={selectedBlog.bannerimg} alt="" className="w-full h-full rounded-sm object-cover" />
                                            </div>
                                            <input className={`block w-[50%] p-0.5 mb-0.5 text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none  dark:border-gray-600 dark:placeholder-gray-400  ${errors.bannerimg ? 'border-red-500' : 'border-gray-300'}`} type="file" accept="image/*" onChange={(e) => setFormData({ ...formData, bannerimg: e.target.files[0] })} required />
                                        </div>
                                        <div className="">
                                            <label className="block text-sm font-semibold mb-2">Select blog category</label>
                                            <Select
                                                className="text-[black]"
                                                defaultValue={formData.category.map(index => options[index])}
                                                onChange={(selectedOptions) => {
                                                    const selectedValues = selectedOptions.map(option => option.value);
                                                    setFormData({ ...formData, category: selectedValues });
                                                }}
                                                components={animatedComponents}
                                                options={options}
                                                isMulti
                                                placeholder="Select Technology Images"
                                            />
                                        </div>
                                        <div className="">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`} >
                                                Serial No*
                                            </label>
                                            <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`}
                                                type="number"
                                                id="s_no"
                                                value={formData.s_no}
                                                placeholder='Serial no'
                                                onChange={(e) => setFormData({ ...formData, s_no: e.target.value })}
                                            />
                                        </div>
                                        <div className="">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.slug ? 'text-red-500' : 'text-white'}`} htmlFor="slug">
                                                Slug*
                                            </label>
                                            <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.slug ? 'border-red-500' : 'border-gray-300'}`}
                                                type="text"
                                                id="slug"
                                                value={formData.slug}
                                                placeholder='https://codebeans.net/blog/"Slug"'
                                                onChange={(e) => setFormData({ ...formData, slug: e.target.value })}
                                            />
                                        </div>
                                        <div className="">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.title ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                                Title*
                                            </label>
                                            <textarea className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title ? 'border-red-500' : 'border-gray-300'}`}
                                                type="text"
                                                id="title"
                                                value={formData.title}
                                                placeholder='Title'
                                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}

                                            />
                                        </div>
                                        <div className="">
                                            <label className="block !text-current text-sm font-normal mb-2" htmlFor="service-description" >
                                                Blog Description
                                            </label>
                                            <textarea
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="blogdetail"
                                                value={formData.blogdetail}
                                                placeholder='Blog Description'
                                                onChange={(e) => setFormData({ ...formData, blogdetail: e.target.value })}
                                            />
                                        </div>
                                        {formData.headingdata.map((entry, index) => (
                                            <React.Fragment key={`heading-${index}`}>
                                                <>
                                                    <div className="">
                                                        <label className="block !text-current text-sm font-normal mb-2" htmlFor={`heading-${index}`}>
                                                            Heading {index + 1}
                                                        </label>
                                                        <textarea id={`heading-${index}`} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                            value={entry.heading} placeholder='Heading' onChange={(e) => handleInputChange(e, index, 'heading')} />
                                                    </div>
                                                    <div className="">
                                                        <label className="block !text-current text-sm font-normal mb-2" htmlFor={`description-${index}`}>
                                                            Description {index + 1}
                                                        </label>
                                                        <textarea id={`description-${index}`} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                            value={entry.detail} placeholder='Description' onChange={(e) => handleInputChange(e, index, 'detail')} />
                                                    </div>
                                                    <div className="">
                                                        {entry.images.image1 && (
                                                            <label className="flex relative justify-center rounded-md overflow-hidden" htmlFor={`fileInput-image1-${index}`}>
                                                                <label className='text-blue-500 absolute right-1 top-1 cursor-pointer transition duration-300 ease-in-out hover:scale-125'>
                                                                    <input
                                                                        id={`fileInput-image1-${index}`}
                                                                        className='hidden'
                                                                        type="file"
                                                                        accept="image/*"
                                                                        onChange={(e) => handleEditExistingImage(e, index, 'image1')}
                                                                    />
                                                                    <BsFillPencilFill />
                                                                </label>
                                                                <figure className="w-full h-14" key={index}>
                                                                    <img className='h-full w-full object-cover overflow-hidden' src={typeof entry.images.image1 === 'string' ? entry.images.image1 : URL.createObjectURL(entry.images.image1)} alt='' />
                                                                </figure>
                                                                <button className='absolute bottom-1 text-red-600 transition duration-300 ease-in-out hover:scale-125' type="button" onClick={() => handleRemoveImage(index, 'image1')}><FaTrash /></button>
                                                            </label>
                                                        )}
                                                    </div>
                                                    <div className="">
                                                        {entry.images.image2 && (
                                                            <label className="flex relative justify-center rounded-md overflow-hidden" htmlFor={`fileInput-image2-${index}`}>
                                                                <label className="text-blue-500 absolute right-1 top-1 cursor-pointer transition duration-300 ease-in-out hover:scale-125">
                                                                    <input
                                                                        id={`fileInput-image2-${index}`}
                                                                        className='hidden'
                                                                        type="file"
                                                                        accept="image/*"
                                                                        onChange={(e) => handleEditExistingImage(e, index, 'image2')}
                                                                    />
                                                                    <BsFillPencilFill />
                                                                </label>
                                                                <figure className="w-full h-14" key={index}>
                                                                    <img className='h-full w-full object-cover overflow-hidden' src={typeof entry.images.image2 === 'string' ? entry.images.image2 : URL.createObjectURL(entry.images.image2)} alt='' />
                                                                </figure>
                                                                <button className='absolute bottom-1 text-red-600 transition duration-300 ease-in-out hover:scale-125' type="button" onClick={() => handleRemoveImage(index, 'image2')}><FaTrash /></button>
                                                            </label>
                                                        )}
                                                    </div>
                                                    <React.Fragment key={`new-field-${index}`}>
                                                        <div className=''>
                                                            <label className="block !text-current text-semi-bold mb-1" >
                                                                First Blog Image 1
                                                            </label>
                                                            <input className="block p-0.5 w-full text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50  focus:outline-none dark:border-gray-600 dark:placeholder-gray-400"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(e) => handleFileChange(e, index, 1)}
                                                            />
                                                        </div>
                                                        <div className=''>
                                                            <label className="block !text-current text-semi-bold mb-1" >
                                                                Second Blog Image 2
                                                            </label>
                                                            <input className="block w-full p-0.5 text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:placeholder-gray-400"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(e) => handleFileChange(e, index, 2)}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                </>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    <div className="mt-5 flex gap-3 justify-center">
                                        <button
                                            className="btn-primary flex" onClick={handleAddField}><span>Add Field</span>
                                        </button>
                                        <button
                                            className="btn-secondary flex" onClick={handleRemoveField}><span>Remove Field</span>
                                        </button>
                                    </div>
                                    <div className="flex justify-center">
                                        <button onClick={() => { setButtonLoader(true); handleModifyBlog() }} className="btn-submit btn text-center text-white p-5 rounded mt-5">
                                            {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            ) : null}
        </>
    );
};

export default Blog;
