import { BsFillPencilFill, BsPlusLg } from "react-icons/bs";
import PulseLoader from "react-spinners/PulseLoader";
import React, { useState, useEffect } from 'react';
import { FcUpload } from "react-icons/fc";
import { FaTrash } from "react-icons/fa";
import Loading from './loader';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import axios from "axios";

const REACT_APP_HIRING_POST = process.env.REACT_APP_HIRING_POST;
const REACT_APP_HIRING_FORM = process.env.REACT_APP_HIRING_FORM;

const HireingPost = () => {

    const [buttonloader, setButtonLoader] = useState(false);
    const [hiringpost, setHiringPost] = useState([]);
    const [hiringform, setHiringForm] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [selecthiring, setSelectHiring] = useState(null);
    const [loading, setLoading] = useState(true);
    const [numField, setNumField] = useState(1);
    const [numPoint, setNumPoint] = useState(1);
    const [numBanner, setNumBanner] = useState(1);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        bannerimg: '',
        title: '',
        sub_dec: '',
        quote: '',
        value1: '',
        value2: '',
        value3: '',
        headingdata: [{ heading: '' }],
        points: [{ point: '' }],
        banner_box: [{ banner_title: '', dec: '', image: '' }]
    });

    const fetchData = async () => {
        try {
            const [postRes, formRes] = await Promise.all([
                axios(`${REACT_APP_HIRING_POST}all`),
                axios(`${REACT_APP_HIRING_FORM}all`)
            ]);

            setHiringPost(postRes.data);
            console.log("postRes.data", postRes.data)
            setHiringForm(formRes.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (e, index) => {
        const image = [...formData.banner_box];
        image[index].image = e.target.files[0];
        setFormData({ ...formData, banner_box: image });
    };

    const handleInputChange = (e, index, type) => {
        const updatedFormData = { ...formData };
        if (!updatedFormData.headingdata[index]) {
            updatedFormData.headingdata[index] = { heading: '' };
        } else if (!updatedFormData.banner_box[index]) {
            updatedFormData.banner_box[index] = { banner_title: '', dec: '', image: '' };
        } else if (!updatedFormData.points[index]) {
            updatedFormData.points[index] = { point: '' };
        }

        if (type === 'heading') {
            updatedFormData.headingdata[index].heading = e.target.value;
        } else if (type === 'point') {
            updatedFormData.points[index].point = e.target.value;
        } else if (type === 'banner_title') {
            updatedFormData.banner_box[index].banner_title = e.target.value;
        } else if (type === 'dec') {
            updatedFormData.banner_box[index].dec = e.target.value;
        }

        setFormData(updatedFormData);
    };

    const handleCreate = async () => {
        let hasError = false;
        const newErrors = {};
        const uploadData = new FormData();

        // Check required fields
        ['title', 'sub_dec', 'quote', 'headingdata'].forEach(field => {
            if (!formData[field] || (Array.isArray(formData[field]) && formData[field].length === 0)) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (!formData.bannerimg) {
            uploadData.append('bannerimg', '');
        } else {
            uploadData.append('bannerimg', formData.bannerimg);
        }

        if (!formData.image) {
            uploadData.append('image', '');
        } else {
            uploadData.append('image', formData.image);
        }

        setButtonLoader(true);

        // Append heading data
        formData.headingdata.forEach((entry, index) => {
            uploadData.append(`heading${index + 1}`, entry.heading);
        });

        // Append points data
        formData.points.forEach((entry, index) => {
            uploadData.append(`point${index + 1}`, entry.point);
        });

        // Append banner box data
        formData.banner_box.forEach((entry, index) => {
            uploadData.append(`banner_title${index + 1}`, entry.banner_title);
            uploadData.append(`dec${index + 1}`, entry.dec);
            uploadData.append(`image${index + 1}`, entry.image);
        });

        uploadData.append('title', formData.title);
        uploadData.append('sub_dec', formData.sub_dec);
        uploadData.append('quote', formData.quote);
        uploadData.append('value1', formData.value1);
        uploadData.append('value2', formData.value2);
        uploadData.append('value3', formData.value3);


        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.post(`${REACT_APP_HIRING_POST}`, uploadData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${refreshToken}`
                }
            });

            if (response.status === 201) {
                Swal.fire({ title: "Hiring Post successfully Added", icon: "success", timer: 1500 });
                resetForm();
                setShowModal(false);
                fetchData();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({ title: "Ohh! Failed to save Hiring Post. Try again!", text: error.response?.data?.message || error.message || "An error occurred", icon: "error", timer: 1500 });
        } finally {
            setButtonLoader(false);
        }
    };

    const handleEdit = (id) => {
        const selectHiring = hiringpost.find(item => item._id === id);
        setSelectHiring(selectHiring);
        setEditModal(true);
        setFormData({
            ...selectHiring,
            bannerimg: selectHiring.bannerimg,
            title: selectHiring.title,
            sub_dec: selectHiring.sub_dec,
            quote: selectHiring.quote,
            value1: selectHiring.value1,
            value2: selectHiring.value2,
            value3: selectHiring.value3,
            headingdata: selectHiring.headingdata.map(entry => ({
                heading: entry.heading,
            })),
            points: selectHiring.points.map(entry => ({
                point: entry.point
            })),
            banner_box: selectHiring.banner_box.map(entry => ({
                banner_title: entry.banner_title || '',
                dec: entry.dec || '',
                image: entry.image || ''
            }))
        })
    };

    const handleModifyHiring = async () => {
        setButtonLoader(true);
        const uploadData = new FormData();

        if (selecthiring.bannerimg) {
            uploadData.append('bannerimg', selecthiring.bannerimg);
        } else if (formData.bannerimg) {
            uploadData.append('bannerimg', formData.bannerimg);
        }

        // Append heading data
        formData.headingdata.forEach((entry, index) => {
            uploadData.append(`heading${index + 1}`, entry.heading);
        });

        // Append points data
        formData.points.forEach((entry, index) => {
            uploadData.append(`point${index + 1}`, entry.point);
        });

        // Append banner box data
        formData.banner_box.forEach((entry, index) => {
            uploadData.append(`banner_title${index + 1}`, entry.banner_title);
            uploadData.append(`dec${index + 1}`, entry.dec);
            uploadData.append(`image${index + 1}`, entry.image);
        });

        uploadData.append('title', formData.title);
        uploadData.append('sub_dec', formData.sub_dec);
        uploadData.append('quote', formData.quote);
        uploadData.append('value1', formData.value1);
        uploadData.append('value2', formData.value2);
        uploadData.append('value3', formData.value3);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_HIRING_POST}${selecthiring._id}`, uploadData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (response.status === 200) {
                Swal.fire({ title: "Hiring Post Has been successfully Modified", icon: "success", timer: 1500 });
                setEditModal(false);
                resetForm();
                fetchData();
                // window.location.reload();
            }

        } catch (error) {
            console.error('Error modifying Post:', error);
            Swal.fire({ title: "Ohh! Failed to modify Hiring Post. Try again!", text: error.response?.data?.message || error.message || "An error occurred", icon: "error", timer: 1500 });
        } finally {
            setButtonLoader(false);
        }
    }

    const handleDelete = (id) => {
        confirmDelete(id);
    };

    const confirmDelete = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCloseButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
            timer: 1500
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const refreshToken = Cookies.get('refreshToken');
                    const response = await axios.delete(`${REACT_APP_HIRING_POST}${id}`, {
                        headers: { 'Authorization': `Bearer ${refreshToken}` }
                    });

                    if (response.status === 200) {
                        Swal.fire({ title: "Deleted!", text: "Your file has been deleted.", icon: "success", timer: 1500 });
                        fetchData();
                        window.location.reload();
                    }
                } catch (error) {
                    console.error('Error deleting blog:', error);
                    Swal.fire({ title: "Error!", text: error.response?.data?.message || error.message || "An error occurred", icon: "error", timer: 1500 });
                }
            }
        })
    };

    const handleRemoveImage = (index, imageType) => {
        const newBannerBoxData = [...formData.banner_box];
        newBannerBoxData[index].image[imageType] = null;
        setFormData({ ...formData, banner_box: newBannerBoxData });
    };

    const handleEditExistingImage = (event, index, field) => {
        const newImage = event.target.files[0];
        const newBannerBoxData = [...formData.banner_box];
        newBannerBoxData[index].image[field] = newImage;
        setFormData({ ...formData, banner_box: newBannerBoxData });
    };

    const handleAddField = () => {
        setNumField(numField + 1);
        setFormData(prevState => ({
            ...prevState,
            headingdata: [
                ...prevState.headingdata,
                { heading: '' }
            ]
        }));
    };

    const handleAddPoint = () => {
        setNumPoint(numPoint + 1);
        setFormData(prevState => ({
            ...prevState,
            points: [
                ...prevState.points,
                { point: '' }
            ]
        }));
    };

    const handleAddBanner = () => {
        setNumBanner(numBanner + 1);
        setFormData(prevState => ({
            ...prevState,
            banner_box: [
                ...prevState.banner_box,
                {
                    banner_title: '', // Correct property
                    dec: '',         // Correct property
                    image: ''       // Correct property
                }
            ]
        }));
    };

    const handleRemoveField = () => {
        if (formData.headingdata.length > 1) {
            setNumField(numField - 1);
            setFormData(prevState => ({
                ...prevState,
                headingdata: prevState.headingdata.slice(0, -1)
            }));
        }
    };

    const handleRemovepoint = () => {
        if (formData.points.length > 1) {
            setNumPoint(numPoint - 1);
            setFormData(prevState => ({
                ...prevState,
                points: prevState.points.slice(0, -1)
            }));
        }
    };

    const handleRemoveBanner = () => {
        if (formData.banner_box.length > 1) {
            setNumBanner(numBanner - 1);
            setFormData(prevState => ({
                ...prevState,
                banner_box: prevState.banner_box.slice(0, -1)
            }));
        }
    };

    const resetForm = () => {
        setFormData({
            bannerimg: '',
            title: '',
            sub_dec: '',
            quote: '',
            value1: "", value2: "", value3: "",
            headingdata: [{ heading: '' }], // Ensure this is an array of objects
            points: [{ point: '' }],
            banner_box: [{ banner_title: '', dec: '', image: '' }]
        });
        setErrors({});
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <section className="shadow-blacke dark:shadow-whitee rounded-xl p-4 w-full mb-10">
                        <div className="flex items-center justify-between border-b pb-3">
                            <h2 className="text-black dark:text-white font-bold text-4xl leading-normal">Our Some Hiring Position</h2>
                            <button type="btn" onClick={() => { setShowModal(true); resetForm(); }} className="cursor-pointer hover:shadow-inner shadow-sm btn-secondary flex text-white right-0"><span className=''><BsPlusLg /> </span> Add Post</button>
                        </div>
                        <div className="overflow-hidden overflow-y-scroll max-h-[500px] p-3">
                            <div className="grid grid-cols-3 items-center justify-center gap-5 py-4 my-6">
                                {hiringpost.map((item) => (
                                    <div className="group relative rounded-3xl shadow-blacke dark:shadow-whitee dark:bg-black/50 p-4 h-full w-full transition duration-300 ease-in-out cursor-pointer overflow-hidden" key={item._id}>
                                        <h2 className="text-black dark:text-white font-bold my-2">{item.title}</h2>
                                        <p className="text-black dark:text-white line-clamp-3 my-2">{item.sub_dec}</p>
                                        <p className="text-black dark:text-white line-clamp-3 my-2">"{item.quote}"</p>
                                        <div className="absolute top-2 right-3 z-50">
                                            <button className="font-medium transition-all duration-300 text-[black] bg-[white] rounded-full p-2 hover:scale-125 top-0" onClick={() => handleEdit(item._id)}><BsFillPencilFill /></button>
                                        </div>
                                        <ul className="absolute inset-x-0 opacity-0 hover:opacity-100 overflow-hidden -bottom-full flex items-center justify-center gap-10 pb-5 transition-all duration-300 group-hover:inset-y-0 group-hover:bg-gradient-to-t group-hover:from-white/70">
                                            <li className='w-2 h-2'>
                                                <button type="button" onClick={() => handleDelete(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="flex absolute font-medium transition-all duration-300 bottom-6 text-[black] bg-[white] rounded-full p-2 hover:scale-125 hover:underline">
                                                    <FaTrash />
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                    <section className="shadow-blacke dark:shadow-whitee rounded-xl p-4 w-full h-max-[300px] overflow-y-scroll mb-10">
                        <h2 className="text-black dark:text-white font-bold text-4xl leading-normal border-b">Some Submiting Hiring Data</h2>
                        <div className="text-left items-center justify-between grid grid-cols-6 mt-3 gap-3 font-semibold text-black dark:text-white">
                            <p>Name</p>
                            <p>E-mail</p>
                            <p>Mobile No.</p>
                            <p>Position</p>
                            <p>description</p>
                            <p className="text-center" >Download C.V Delete</p>
                        </div>
                        {hiringform.map((item) => (
                            <div className="grid grid-cols-6  text-left items-center justify-between mt-3 text-black dark:text-white border-b gap-3 " key={item._id}>
                                <p>{item.name}</p>
                                <p>{item.email}</p>
                                <p>{item.mobile}</p>
                                <p>{item.position}</p>
                                <p>{item.description}</p>
                                <div className="flex items-center justify-center text-center gap-6">
                                    <button className="rotate-180 font-medium transition-all duration-300 bottom-6 text-[black] bg-[white] rounded-full p-2 hover:scale-125 hover:underline">
                                        <a href={item.file} download><FcUpload /></a>
                                    </button>
                                    <button className="font-medium transition-all duration-300 bottom-6 text-[black] bg-[white] rounded-full p-2 hover:scale-125 hover:underline">
                                        <FaTrash />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </section>
                </>
            )}
            {showModal ? (
                <div className="justify-center items-center bg-[#000000a8] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50" data-aos="zoom-out" data-aos-duration="500">
                    <div className="relative rounded-lg overflow-hidden sm:w-[90%] md:w-[90%] lg:max-w-2xl xl:lg:max-w-2xl md:max-w-2xl sm:max-w-2xl">
                        {/*content*/}
                        <div className="border-0 shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between rounded-t p-5">
                                <h1 className='pb-2 font-bold text-secondary-500 text-4xl'>
                                    Add Hiring Post
                                </h1>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => { setShowModal(false); resetForm(); }}
                                >
                                    <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative px-6 pb-6 flex-auto h-[38rem] overflow-y-scroll overflow-hidden">
                                <div className='grid gap-5 sm:grid-cols-2 lg:grid-cols-2'>
                                    <div className="div">
                                        <label className={`block text-semi-bold mb-1 ${errors.bannerimg ? 'text-red-500' : 'text-white'}`}>
                                            Banner Image
                                        </label>
                                        <input className={`block p-0.5 mb-0.5 w-full text-gray-900 border-2 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:placeholder-gray-400 ${errors.bannerimg ? 'border-red-500' : 'border-gray-300'} `} type="file" accept="image/*" onChange={(e) => setFormData({ ...formData, bannerimg: e.target.files[0] })} />
                                    </div>
                                    <div className="">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.title ? 'text-red-500' : 'text-white'}`} >
                                            Title*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title ? 'border-red-500' : 'border-gray-300'}`}
                                            type="text"
                                            id="title"
                                            value={formData.title}
                                            placeholder='Title'
                                            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                        />
                                    </div>
                                    <div className="">
                                        <label className="block text-sm font-semibold mb-2" htmlFor="service-description" >
                                            Title Description
                                        </label>
                                        <textarea
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="blogdetail"
                                            value={formData.sub_dec}
                                            placeholder='Blog Description'
                                            onChange={(e) => setFormData({ ...formData, sub_dec: e.target.value })}
                                        />
                                    </div>
                                    <div className="">
                                        <label className="block text-sm font-semibold mb-2" htmlFor="service-description" >
                                            Quote
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="blogdetail"
                                            value={formData.quote}
                                            placeholder='Blog Description'
                                            onChange={(e) => setFormData({ ...formData, quote: e.target.value })}
                                        />
                                    </div>
                                    <div className="">
                                        {[...Array(numField)].map((_, index) => (
                                            <React.Fragment key={index}>
                                                <div className="">
                                                    <label className="block text-sm font-semibold mb-2">
                                                        Explane About Position in Heading {index + 1}
                                                    </label>
                                                    <textarea
                                                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                        value={formData.headingdata[index]?.heading || ''}
                                                        placeholder='Heading'
                                                        onChange={(e) => handleInputChange(e, index, 'heading')}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        ))}
                                        <div className="mt-5 flex gap-3 justify-center">
                                            <button
                                                className="btn-primary flex text-[12px]"
                                                onClick={handleAddField}><span>Add Field</span>
                                            </button>
                                            <button
                                                className="btn-secondary flex text-[12px]"
                                                onClick={handleRemoveField}><span>Remove Field</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="">
                                        {[...Array(numPoint)].map((_, index) => (
                                            <React.Fragment key={index}>
                                                <div className="">
                                                    <label htmlFor="service-sub-heading" className="block text-sm font-semibold mb-2">
                                                        Highlighting Some point's {index + 1}
                                                    </label>
                                                    <textarea
                                                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                        value={formData.points[index]?.point || ''}
                                                        placeholder='Point'
                                                        onChange={(e) => handleInputChange(e, index, 'point')}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        ))}
                                        <div className="mt-5 flex gap-3 justify-center">
                                            <button
                                                className="btn-primary flex text-[12px]"
                                                onClick={handleAddPoint}><span>Add Field</span>
                                            </button>
                                            <button
                                                className="btn-secondary flex text-[12px]"
                                                onClick={handleRemovepoint}><span>Remove Field</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <h2 className="my-4">Checked the check box for Showing Default Banner.</h2>
                                <div className="flex items-center justify-between mb-3">
                                    <label className="checkbox-btn">
                                        <label htmlFor="checkbox1">FULL TIME</label>
                                        <input id="checkbox" type="checkbox" checked={formData.value1 === 'FULL TIME'}
                                            onChange={(e) => setFormData({ ...formData, value1: e.target.checked ? 'FULL TIME' : '' })} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="checkbox-btn">
                                        <label htmlFor="checkbox">PART TIME</label>
                                        <input id="checkbox" type="checkbox" checked={formData.value2 === 'PART TIME'}
                                            onChange={(e) => setFormData({ ...formData, value2: e.target.checked ? 'PART TIME' : '' })} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="checkbox-btn">
                                        <label htmlFor="checkbox">HOURLY BASIS</label>
                                        <input id="checkbox" type="checkbox" checked={formData.value3 === 'HOURLY BASIS'}
                                            onChange={(e) => setFormData({ ...formData, value3: e.target.checked ? 'HOURLY BASIS' : '' })} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className='grid gap-5 sm:grid-cols-2 lg:grid-cols-2'>
                                    {[...Array(numBanner)].map((_, index) => (
                                        <React.Fragment key={index}>
                                            <div className="">
                                                <label className="block text-sm font-semibold mb-2">
                                                    Add exiter Banner {index + 1}
                                                </label>
                                                <textarea
                                                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                    value={formData.banner_box[index]?.banner_title || ''}
                                                    placeholder='Banner Title'
                                                    onChange={(e) => handleInputChange(e, index, 'banner_title')}
                                                />
                                            </div>
                                            <div className="">
                                                <label className="block text-sm font-semibold mb-2">
                                                    Add Banner Description {index + 1}
                                                </label>
                                                <textarea
                                                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                    placeholder='Banner Description'
                                                    value={formData.banner_box[index]?.dec || ''}
                                                    onChange={(e) => handleInputChange(e, index, 'dec')}
                                                />
                                            </div>
                                            <div className=''>
                                                <label className="block !text-current text-semi-bold mb-1" >
                                                    Banner Image {index + 1}
                                                </label>
                                                <input className="block p-0.5 w-full text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50  focus:outline-none dark:border-gray-600 dark:placeholder-gray-400"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => handleFileChange(e, index, 'image')}
                                                />
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div className="mt-5 flex gap-3 justify-center">
                                    <button
                                        className="btn-primary flex text-[12px]"
                                        onClick={handleAddBanner}><span>Add Field</span>
                                    </button>
                                    <button
                                        className="btn-secondary flex text-[12px]"
                                        onClick={handleRemoveBanner}><span>Remove Field</span>
                                    </button>
                                </div>
                                <div className="flex justify-center ">
                                    <button className="btn-submit btn text-center text-white p-5 rounded mt-5" onClick={() => { setButtonLoader(true); handleCreate() }} >
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            ) : null}

            {selecthiring && editModal && (
                <div className="justify-center items-center bg-[#000000a8] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50" data-aos="zoom-out" data-aos-duration="500">
                    <div className="relative rounded-lg overflow-hidden sm:w-[90%] md:w-[90%] lg:max-w-2xl xl:lg:max-w-2xl md:max-w-2xl sm:max-w-2xl">
                        {/*content*/}
                        <div className="border-0 shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between rounded-t p-5">
                                <h1 className='pb-2 font-bold text-secondary-500 text-4xl'>
                                    Update Hiring Post
                                </h1>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => { setEditModal(false); resetForm(); }}
                                >
                                    <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative px-6 pb-6 flex-auto h-[38rem] overflow-y-scroll overflow-hidden">
                                <div className='grid gap-5 sm:grid-cols-2 lg:grid-cols-2'>
                                    <div className="div">
                                        <label className={`block text-semi-bold mb-1 ${errors.bannerimg ? 'text-red-500' : 'text-white'}`}>
                                            Banner Image
                                        </label>
                                        {selecthiring.bannerimg && (
                                            <div className="max-h-12 mb-2">
                                                <img src={selecthiring.bannerimg} alt="" className="w-full h-full rounded-sm object-cover" />
                                            </div>
                                        )}
                                        <input className={`block p-0.5 mb-0.5 w-full text-gray-900 border-2 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:placeholder-gray-400 ${errors.bannerimg ? 'border-red-500' : 'border-gray-300'} `} type="file" accept="image/*" onChange={(e) => setFormData({ ...formData, bannerimg: e.target.files[0] })} />
                                    </div>
                                    <div className="">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.title ? 'text-red-500' : 'text-white'}`} >
                                            Title*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title ? 'border-red-500' : 'border-gray-300'}`}
                                            type="text"
                                            id="title"
                                            value={formData.title}
                                            placeholder='Title'
                                            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                        />
                                    </div>
                                    <div className="">
                                        <label className="block text-sm font-semibold mb-2" htmlFor="service-description" >
                                            Title Description
                                        </label>
                                        <textarea
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="blogdetail"
                                            value={formData.sub_dec}
                                            placeholder='Blog Description'
                                            onChange={(e) => setFormData({ ...formData, sub_dec: e.target.value })}
                                        />
                                    </div>
                                    <div className="">
                                        <label className="block text-sm font-semibold mb-2" htmlFor="service-description" >
                                            Quote
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="blogdetail"
                                            value={formData.quote}
                                            placeholder='Blog Description'
                                            onChange={(e) => setFormData({ ...formData, quote: e.target.value })}
                                        />
                                    </div>
                                    <div className="">
                                        {formData.headingdata.map((entry, index) => (
                                            <React.Fragment key={`heading-${index}`}>
                                                <div className="">
                                                    <label className="block text-sm font-semibold mb-2">
                                                        Explane About Position in Heading {index + 1}
                                                    </label>
                                                    <textarea
                                                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                        value={entry.heading}
                                                        placeholder='Heading'
                                                        onChange={(e) => handleInputChange(e, index, 'heading')}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        ))}
                                        <div className="mt-5 flex gap-3 justify-center">
                                            <button
                                                className="btn-primary flex text-[12px]"
                                                onClick={handleAddField}><span>Add Field</span>
                                            </button>
                                            <button
                                                className="btn-secondary flex text-[12px]"
                                                onClick={handleRemoveField}><span>Remove Field</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="">
                                        {formData.points.map((entry, index) => (
                                            <React.Fragment key={`point-${index}`}>
                                                <div className="">
                                                    <label htmlFor="service-sub-heading" className="block text-sm font-semibold mb-2">
                                                        Highlighting Some point's {index + 1}
                                                    </label>
                                                    <textarea
                                                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                        value={entry.point}
                                                        placeholder='Point'
                                                        onChange={(e) => handleInputChange(e, index, 'point')}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        ))}
                                        <div className="mt-5 flex gap-3 justify-center">
                                            <button
                                                className="btn-primary flex text-[12px]"
                                                onClick={handleAddPoint}><span>Add Field</span>
                                            </button>
                                            <button
                                                className="btn-secondary flex text-[12px]"
                                                onClick={handleRemovepoint}><span>Remove Field</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <h2 className="my-4">Checked the check box for Showing Default Banner.</h2>
                                <div className="flex items-center justify-between mb-3">
                                    <label className="checkbox-btn">
                                        <label htmlFor="checkbox1">Full time</label>
                                        <input id="checkbox1" type="checkbox" checked={formData.value1 === 'FULL TIME'}
                                            onChange={(e) => setFormData({ ...formData, value1: e.target.checked ? 'FULL TIME' : '' })} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="checkbox-btn">
                                        <label htmlFor="checkbox2">Part time</label>
                                        <input id="checkbox2" type="checkbox" checked={formData.value2 === 'PART TIME'}
                                            onChange={(e) => setFormData({ ...formData, value2: e.target.checked ? 'PART TIME' : '' })} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="checkbox-btn">
                                        <label htmlFor="checkbox3">Hourly Basis</label>
                                        <input id="checkbox3" type="checkbox" checked={formData.value3 === 'HOURLY BASIS'}
                                            onChange={(e) => setFormData({ ...formData, value3: e.target.checked ? 'HOURLY BASIS' : '' })} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className='grid gap-5 sm:grid-cols-2 lg:grid-cols-2'>
                                    {formData.banner_box.map((entry, index) => (
                                        <React.Fragment key={`banner_title-${index}`}>
                                            <div className="">
                                                <label className="block text-sm font-semibold mb-2">
                                                    Add exiter Banner {index + 1}
                                                </label>
                                                <textarea
                                                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                    value={entry.banner_title}
                                                    placeholder='Banner Title'
                                                    onChange={(e) => handleInputChange(e, index, 'banner_title')}
                                                />
                                            </div>
                                            <div className="">
                                                <label className="block text-sm font-semibold mb-2">
                                                    Add Banner Description {index + 1}
                                                </label>
                                                <textarea
                                                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                    placeholder='Banner Description'
                                                    value={entry.dec}
                                                    onChange={(e) => handleInputChange(e, index, 'dec')}
                                                />
                                            </div>
                                            <div className=''>
                                                {entry.image && (
                                                    <label className="flex relative justify-center rounded-md overflow-hidden">
                                                        <label className='text-blue-500 absolute right-1 top-1 cursor-pointer transition duration-300 ease-in-out hover:scale-125' >
                                                            <input className="hidden"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(e) => handleEditExistingImage(e, index, 'image')}
                                                            />
                                                        </label>
                                                        <figure className="w-full max-h-14" key={index}>
                                                            <img className="h-full w-full object-cover overflow-hidden"
                                                                src={typeof entry.image === 'string' ? entry.image : URL.createObjectURL(entry.image)} alt='' />
                                                        </figure>
                                                        <button className='absolute bottom-1 text-red-600 transition duration-300 ease-in-out hover:scale-125' type="button" onClick={() => handleRemoveImage(index, 'image')}><FaTrash /></button>
                                                    </label>
                                                )}
                                                <React.Fragment key={`new-Field-${index}`}>
                                                    <div className=''>
                                                        <label className="block !text-current text-semi-bold mb-1" >
                                                            Banner Image {index + 1}
                                                        </label>
                                                        <input className="block p-0.5 w-full text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50  focus:outline-none dark:border-gray-600 dark:placeholder-gray-400"
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={(e) => handleFileChange(e, index, 'image')}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div className="mt-5 flex gap-3 justify-center">
                                    <button
                                        className="btn-primary flex text-[12px]"
                                        onClick={handleAddBanner}><span>Add Field</span>
                                    </button>
                                    <button
                                        className="btn-secondary flex text-[12px]"
                                        onClick={handleRemoveBanner}><span>Remove Field</span>
                                    </button>
                                </div>
                                <div className="flex justify-center ">
                                    <button className="btn-submit btn text-center text-white p-5 rounded mt-5" onClick={() => { setButtonLoader(true); handleModifyHiring() }} >
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            )}
        </>
    )
};

export default HireingPost;