import React, { useState, useEffect } from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import { BsFillPencilFill, BsPlusLg } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import Loadimg from './loader';
import axios from 'axios';

const REACT_GALLERY_PHOTO = process.env.REACT_APP_GALLERYPHOTO;

const Gallery = () => {

  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [buttonloader, setButtonLoader] = useState(false);
  const [data, setData] = useState([]);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    s_no: ''
  });

  const fetchData = async () => {
    try {
      const res = await axios.get(`${REACT_GALLERY_PHOTO}all`);
      setData(res.data);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
    }
  }

  const getNextSerialNumber = () => {
    const existingSerialNumbers = data.map(item => item.s_no);
    const maxSerialNumber = Math.max(...existingSerialNumbers);
    return maxSerialNumber !== -Infinity ? maxSerialNumber + 1 : 1;
  };

  const handleFileChange = async (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    let hasError = false;
    const newErrors = {};

    ['s_no'].forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = true;
        hasError = true;
      }
      if (!selectedFile) {
        newErrors['image'] = true;
        hasError = true;
      }
    });

    if (hasError) {
      setErrors(newErrors);
      setButtonLoader(false);
      return; // Prevent submission if required fields are empty
    }

    setButtonLoader(true); // Show BeatLoader
    const form = new FormData();
    form.append('image', selectedFile);
    form.append('s_no', formData.s_no);
    try {
      const refreshToken = Cookies.get('refreshToken');
      const response = await axios.post(`${REACT_GALLERY_PHOTO}`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${refreshToken}`
        },
      });

      if (response.status === 201) {
        Swal.fire({
          title: "Image Added successfully.",
          icon: "success",
          timer: 1500
        });
        setFormData({ s_no: '' }); // Reset the form data
        setShowModal(false);
        fetchData(); // Fetch updated data after adding the image
        window.location.reload()
      }
    } catch (error) {
      Swal.fire({
        title: "Ohh!Failed to Add image.",
        text: error.response?.data?.message || error.message || "An error occurred",
        icon: "error",
        timer: 1500
      });
    } finally {
      setButtonLoader(false); // Hide BeatLoader
    }
  };

  const handleEdit = (id) => {
    const selectImage = data.find(item => item._id === id);
    setSelectedImageId(selectImage);
    setFormData(prevState => ({
      ...prevState,
      s_no: selectImage.s_no,
      image: selectImage.image
    }));
    setEditModal(true);
  };

  const handlemodifyimage = async () => {
    let hasError = false;
    const newErrors = {};

    ['s_no'].forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = true;
        hasError = true;
      }
    });

    if (hasError) {
      setErrors(newErrors);
      setButtonLoader(false);
      return; // Prevent submission if required fields are empty
    }

    setButtonLoader(true);
    const form = new FormData();

    if (selectedFile) {
      form.append('image', selectedFile);
    }
    form.append('s_no', formData.s_no);

    try {
      const refreshToken = Cookies.get('refreshToken');
      const response = await axios.put(`${REACT_GALLERY_PHOTO}/${selectedImageId._id}`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${refreshToken}`
        },
      });
      if (response.status === 200) {
        Swal.fire({
          title: "Image Updated successfully.",
          icon: "success",
          timer: 1500
        });
        setFormData({ s_no: '' });
        setEditModal(false);
        fetchData(); // Fetch updated data after updating the image
        window.location.reload();
      }
    } catch (error) {
      Swal.fire({
        title: "Ohh! Failed to Update image.",
        text: error.response?.data?.message || error.message || "An error occurred",
        icon: "error",
        timer: 1500
      });
    } finally {
      setButtonLoader(false);// Hide BeatLoader
    }
  };

  const handleDelete = (id) => {
    confirmDelete(id);
  };

  const confirmDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      timer: 1500
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const refreshToken = Cookies.get('refreshToken');
          const response = await axios.delete(`${REACT_GALLERY_PHOTO}/${id}`, {
            headers: {
              'Authorization': `Bearer ${refreshToken}`
            }
          });
          if (response.status === 200) {
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
              timer: 1500
            });
            fetchData();
            window.location.reload()
          } else {
            Swal.fire({
              title: "Error!",
              text: "Failed to delete image.",
              icon: "error",
              timer: 1500
            });
          }
        } catch (error) {
          console.error('Error While deleteing:', error);
          Swal.fire({
            title: "Error!",
            text: error.response?.data?.message || error.message || "An error occurred",
            icon: "error",
            timer: 1500
          });
        }
      }
    });
  };

  const resetForm = () => {
    const nextSerialNumber = getNextSerialNumber();
    setFormData({ s_no: nextSerialNumber });
    setSelectedFile(null);
    setErrors({});
  };

  useEffect(() => {
    if (!loading) {
      const nextSerialNumber = getNextSerialNumber();
      setFormData(prevState => ({
        ...prevState,
        s_no: nextSerialNumber
      }));
    }
  }, [loading]);

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <>
      <div>
        {loading ? (
          <Loadimg />
        ) : (
          <div>
            {/* //-------------------------- Add New Post button--------------------// */}
            {/* Add New Project button */}
            <div className="relative pb-[100px]">
              <div type="btn" onClick={() => setShowModal(true)} className="absolute cursor-pointer hover:shadow-inner shadow-sm btn-secondary flex text-white right-0 mt-[15px] gap-2"><span className=''><BsPlusLg /></span>Add Image
              </div>
            </div>
            {/* //-------------------Add New Post button end here----------------// */}
            {/* //------Adding mape method for geting all data form backend--------// */}
            <section className="gallery-sec">
              <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 relative">
                {data.map((item) => (
                  <div key={item._id} className="h-[350px] group cursor-pointer shadow-md overflow-hidden text-center rounded-3xl" data-aos="zoom-in" data-aos-duration="1000">
                    <div className="group relative overflow-hidden h-full">
                      <figure className="h-full" >
                        <img src={item.gallery_image} className="h-full w-full object-cover" alt="" />
                      </figure>
                      <li className='w-2 h-2'>
                        <button type="button" onClick={() => handleEdit(item._id)} className="font-medium z-10 flex absolute bg-[white] rounded-full p-2 top-2 right-2 text-blue-600 transition-all duration-300 dark:text-blue-500 hover:scale-125 hover:underline">
                          <BsFillPencilFill />
                        </button>
                      </li>
                      <ul className="absolute inset-x-0 -bottom-full flex items-center justify-center gap-10 pb-5 transition-all duration-300 group-hover:inset-y-0 group-hover:bg-gradient-to-t group-hover:from-white/70">
                        <li className='w-2 h-2'>
                          <button type="button" onClick={() => handleDelete(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="flex absolute font-medium transition-all duration-300 bottom-6 text-[black] bg-[white] rounded-full p-2 hover:scale-125 hover:underline">
                            <FaTrash />
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </section>
            {/* //------mape method for geting all data form backend end Here--------// */}
          </div>
        )}
      </div>
      {
        showModal ? (
          <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
            <div className="relative overscroll-y-auto max-h-full mx-auto lg:max-w-2xl xl:lg:max-w-2xl md:max-w-2xl sm:max-w-2xl my-6">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none p-5">
                {/*header*/}
                <div className="flex items-start justify-between rounded-t">
                  <h4 className='font-bold text-secondary-300 dark:text-secondary-400 text-4xl'>
                    Add Image
                  </h4>
                  <button className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => { setShowModal(false); resetForm(); }} >
                    <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/* body */}
                <div className="relative flex-auto">
                  <div className="mt-2">
                    <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                      Serial No.*
                    </label>
                    <input className={`shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight border-2 focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`}
                      type="number"
                      id="s_no"
                      value={formData.s_no}
                      placeholder='Serial no'
                      onChange={(e) => setFormData({ ...formData, s_no: e.target.value })}
                    />
                  </div>
                  <div className='my-2'>
                    <label className={`block text-sm font-semibold mb-2 ${errors.image ? 'text-red-500' : 'text-white'}`}>
                      Add Image*
                    </label>
                    <input className={`block w-full p-0.5 mb-0.5 text-gray-900 border-2 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none  dark:border-gray-600 dark:placeholder-gray-400  ${errors.image ? 'border-red-500' : 'border-gray-300'}`} type="file" accept="image/*" onChange={handleFileChange} />
                  </div>
                  <div className="flex justify-center mt-5">
                    <button onClick={() => { setButtonLoader(true); handleUpload() }} className="btn-submit btn text-center text-white p-5 rounded">
                      {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null
      }
      {selectedImageId && editModal ? (
        <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
          <div className="relative overscroll-y-auto max-h-full mx-auto lg:max-w-2xl xl:lg:max-w-2xl md:max-w-2xl sm:max-w-2xl my-6">
            {/* content */}
            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none p-5">
              {/* header */}
              <div className="flex items-start justify-between rounded-t">
                <h4 className='font-bold text-secondary-300 dark:text-secondary-400 text-4xl'>
                  Edit
                </h4>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => { setEditModal(false); resetForm(); }}>
                  <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/* body */}
              <div className="relative flex-auto">
                <div className="mt-2">
                  <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                    Serial No.*
                  </label>
                  <input className={`shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight border-2 focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`}
                    type="number"
                    id="s_no"
                    value={formData.s_no}
                    placeholder='Serial no'
                    onChange={(e) => setFormData({ ...formData, s_no: e.target.value })}
                  />
                </div>
                <div className='my-2'>
                  <figure className='w-12 h-12 rounded-md overflow-hidden'>
                    <img className='h-full w-full object-cover overflow-hidden' src={selectedImageId.gallery_image} alt=''></img>
                  </figure>
                  <label className={`block text-sm font-semibold mb-2 ${errors.image ? 'text-red-500' : 'text-white'}`}>
                    Add Image*
                  </label>
                  <input className={`block w-full p-0.5 mb-0.5 text-gray-900 border-2 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none  dark:border-gray-600 dark:placeholder-gray-400 ${errors.image ? 'border-red-500' : 'border-gray-300'}`} type="file" accept="image/*" onChange={handleFileChange} />
                </div>
                <div className="flex justify-center mt-5">
                  <button onClick={() => { setButtonLoader(true); handlemodifyimage() }} className="btn-submit btn text-center text-white p-5 rounded">
                    {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

    </>
  );
};

export default Gallery;
