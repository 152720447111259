import React, { useState, useEffect } from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import { BsFillPencilFill } from "react-icons/bs";
import Createicon from "../assets/svg/crete.svg";
import { FaTrash } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import axios from 'axios';

const REACT_APP_EVENTS = process.env.REACT_APP_EVENTS;

const Events = () => {
    const [showModal, setShowModal] = useState(false);
    const [buttonloader, setButtonLoader] = useState(false);
    const [editModal, setEditModal] = useState(true);
    const [errors, setErrors] = useState({});
    const [event, setEvent] = useState([]);
    const [selectedevent, setSelectedEvent] = useState(null);
    const [formData, setFormData] = useState({
        title: "",
        status: "Deactivated",
        canvas: "",
        startTime: "",
        endingTime: "",
        startdate: "",
        endingdate: "",
    });

    const handleCreate = async () => {
        let hasError = false;
        const newErrors = {};
        const uploadData = new FormData();

        ["title", "canvas", "startTime", "endingTime", "startdate", "endingdate"].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });
        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return;
        }
        setButtonLoader(true);

        ["title", "status", "canvas", "startTime", "endingTime", "startdate", "endingdate"].forEach(
            (field) => uploadData.append(field, formData[field])
        );
        console.log("uploadData :", uploadData)
        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.post(`${REACT_APP_EVENTS}`, uploadData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${refreshToken}`
                }
            });

            if (response.status === 201) {
                Swal.fire({ title: "success", text: "Your Canvas has been saved", icon: "success", timer: 1000 });
                resetForm();
                setShowModal(false);
                window.location.reload();
            }

        } catch (error) {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Ohh! There has Problem with saving your canvas",
                text: error.response?.data?.messsage || error.messsage || "An Error occurred",
                timer: 1000
            });
        } finally {
            setButtonLoader(false); // Hide BeatLoader
        }
    };

    const convertTo24Hour = (time12h) => {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
    };

    const updateEventStatus = async (id, status) => {
        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_EVENTS}${id}`, { status }, {
                headers: { 'Authorization': `Bearer ${refreshToken}` }
            });
            Swal.fire({ title: "Success", text: "Event status updated successfully", icon: "success", timer: 500 });
            window.location.reload();
        } catch (error) {
            console.error('Error updating status:', error);
            Swal.fire('Error', 'There was an error updating the event status', 'error');
        }
    };

    const handleInputChange = (e, id, field = 'status') => {
        const { checked, value, type } = e.target;
        setFormData({ ...formData, checked, value });
        let updatedValue;

        if (type === 'checkbox') {
            updatedValue = checked ? 'Active' : 'Deactivate';
        } else {
            updatedValue = value;
        }

        setEvent(prevEvent =>
            prevEvent.map(item =>
                item._id === id ? { ...item, [field]: updatedValue } : item
            )
        );

        // Update only if the status is changed (for checkbox)
        if (field === 'status') {
            updateEventStatus(id, updatedValue);
        }
    };

    // Handler for the status switch toggle
    const handleStatusToggle = () => {
        setFormData(prevData => ({
            ...prevData,
            status: prevData.status === 'Active' ? 'Deactivate' : 'Active'
        }));
    };

    const handleSaveChanges = async (id) => {
        const updatedEvent = event.find((item) => item._id === id);
        try {
            const refreshToken = Cookies.get('refreshToken');
            await axios.put(`${REACT_APP_EVENTS}/${id}`, updatedEvent, {
                headers: { 'Authorization': `Bearer ${refreshToken}` }
            });
            Swal.fire('Success', 'Event updated successfully!', 'success');
        } catch (error) {
            Swal.fire('Error', 'Failed to update the event', 'error');
            console.error('Error updating event:', error);
        }
    };

    const handleEdit = async (id) => {
        const selectedevent = event.find((item) => item._id === id);
        setSelectedEvent(selectedevent);
        setFormData(prevState => ({
            ...prevState,
            title: selectedevent.title,
            status: selectedevent.status,
            canvas: selectedevent.canvas,
            startTime: selectedevent.startTime,
            endingTime: selectedevent.endingTime,
            startdate: selectedevent.startdate,
            endingdate: selectedevent.endingdate,
        }));
        setEditModal(true);
        console.log("selectedevent1 :", selectedevent);
    };

    const handleSubmitEdit = async () => {
        let hasError = false;
        const newErrors = {};
        const uploadData = new FormData();

        ["title", "canvas", "startTime", "endingTime", "startdate", "endingdate"].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });
        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return;
        }
        setButtonLoader(true);

        ["title", "status", "canvas", "startTime", "endingTime", "startdate", "endingdate"].forEach(
            (field) => uploadData.append(field, formData[field])
        );

        try {
            const refreshToken = Cookies.get('refreshToken')
            const response = await axios.put(`${REACT_APP_EVENTS}${selectedevent._id}`, uploadData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (response.status === 201) {
                Swal.fire({ title: "success", text: "Your Canvas has been Updated", icon: "success", timer: 1000 });
                resetForm();
                setEditModal(false);
                window.location.reload();
            };
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: "error",
                title: "Ohh! There has Problem with saving your canvas",
                text: error.response?.data?.messsage || error.messsage || "An Error occurred",
                timer: 1000
            });
        } finally {
            setButtonLoader(false); // Hide BeatLoader
        }
    };

    const handleDelete = async (id) => {
        // Ask for confirmation before deleting
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });

        if (result.isConfirmed) {
            try {
                const refreshToken = Cookies.get('refreshToken');
                await axios.delete(`${REACT_APP_EVENTS}${id}`, {
                    headers: { 'Authorization': `Bearer ${refreshToken}` }
                });
                // Remove the deleted event from the state
                setEvent((prevEvent) => prevEvent.filter(item => item._id !== id));
                Swal.fire({ text: 'Your event has been deleted.', title: 'success', icon: 'success' });
                window.location.reload();
            } catch (error) {
                Swal.fire('Error', 'Failed to delete the event', 'error');
                console.error('Error deleting event:', error);
            }
        }
    };

    const resetForm = () => {
        setFormData({
            title: "",
            status: "Deactivated",
            canvas: "",
            startTime: "",
            endingTime: "",
            startdate: "",
            endingdate: "",
        })
    };

    useEffect(() => {
        const fetcheventData = async () => {
            try {
                const response = await axios.get(`${REACT_APP_EVENTS}all/post`);
                setEvent(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                Swal.fire('Error', 'Failed to load events', 'error');
            }
        };
        fetcheventData();
    }, []);

    return (
        <>
            <section className='border-2 rounded-3xl shadow overflow-hidden transition duration-300 ease-in-out' data-aos="zoom-in" data-aos-duration="1000">
                <h2 className='text-black dark:text-white font-bold text-2xl leading-normal p-3'>Event's</h2>
                <div className='items-center text-center justify-between font-semibold text-black dark:text-white gap-3'>
                    <ol className='grid grid-cols-7 items-center justify-between font-semibold text-black dark:bg-gray-900 dark:text-white p-3 gap-3'>
                        <li className='text-left'>Event Name</li>
                        <li>Switch Button</li>
                        <li>Starting Date</li>
                        <li>Ending Date</li>
                        <li>Starting Time</li>
                        <li>Ending Time</li>
                        <li>Actions</li>
                    </ol>
                </div>
                <div className='items-center text-center justify-between font-semibold text-black dark:text-white mt-3 gap-3'>
                    {event.map((item) => (
                        <ol className='grid grid-cols-7 text-center items-center border-b pb-2 justify-between font-semibold mb-3 [&>*]:px-3 gap-3' key={item._id}>
                            <li className=''>
                                <input
                                    className='rounded-sm text-center focus-visible:outline-none bg-gray-200 dark:bg-gray-800 text-black border-white dark:border-black dark:text-white'
                                    type='text'
                                    value={item.title}
                                    onChange={(e) => handleInputChange(e, item._id, 'title')}
                                />
                            </li>
                            <li>
                                <div className="toggler">
                                    <input
                                        className='rounded-md text-center focus-visible:outline-none bg-gray-200 dark:bg-gray-800 text-black border-white dark:border-black dark:text-white'
                                        id={`toggler-${item._id}`}
                                        name={`toggler-${item._id}`}
                                        type="checkbox"
                                        value="1"
                                        checked={item.status === 'Active'}
                                        onChange={(e) => handleInputChange(e, item._id)}
                                    />
                                    <label htmlFor={`toggler-${item._id}`}>
                                        <svg className="toggler-on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                            <polyline className="path check" points="100.2,40.2 51.5,88.8 29.8,67.5"></polyline>
                                        </svg>
                                        <svg className="toggler-off" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                            <line className="path line" x1="34.4" y1="34.4" x2="95.8" y2="95.8"></line>
                                            <line className="path line" x1="95.8" y1="34.4" x2="34.4" y2="95.8"></line>
                                        </svg>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <input
                                    className='rounded-sm text-center focus-visible:outline-none bg-gray-200 dark:bg-gray-800 text-black border-white dark:border-black dark:text-white'
                                    type='date'
                                    value={new Date(item.startdate).toISOString().split('T')[0]}
                                    onChange={(e) => handleInputChange(e, item._id, 'startdate')}
                                />
                            </li>
                            <li>
                                <input
                                    className='rounded-sm text-center focus-visible:outline-none bg-gray-200 dark:bg-gray-800 text-black border-white dark:border-black dark:text-white'
                                    type='date'
                                    value={new Date(item.endingdate).toISOString().split('T')[0]}
                                    onChange={(e) => handleInputChange(e, item._id, 'endingdate')}
                                />
                            </li>
                            <li>
                                <input
                                    className='rounded-sm text-center focus-visible:outline-none bg-gray-200 dark:bg-gray-800 text-black border-white dark:border-black dark:text-white'
                                    type='time'
                                    value={convertTo24Hour(item.startTime)}
                                    onChange={(e) => handleInputChange(e, item._id, 'startTime')}
                                />
                            </li>
                            <li>
                                <input
                                    className='rounded-sm text-center focus-visible:outline-none bg-gray-200 dark:bg-gray-800 text-black border-white dark:border-black dark:text-white'
                                    type='time'
                                    value={convertTo24Hour(item.endingTime)}
                                    onChange={(e) => handleInputChange(e, item._id, 'endingTime')}
                                />
                            </li>
                            <li>
                                {/* <BsFillPencilFill className="dark:text-white" /> */}
                                <button onClick={() => handleSaveChanges(item._id)} type='btn' className='px-2 mx-1 bg-primary-200 rounded-md hover:bg-primary-900 ease-in-out duration-150'>Save</button>
                                <button onClick={() => handleEdit(item._id)} type='btn' className='px-2 mx-1 hover:scale-125 ease-in-out duration-150'><BsFillPencilFill className="dark:text-white" /></button>
                                <button onClick={() => handleDelete(item._id)} type='btn' className='px-2 mx-1 hover:scale-125 ease-in-out duration-150'><FaTrash /></button>
                            </li>
                        </ol>
                    ))}
                </div>
                <div className="flex text-center justify-center items-center m-4" data-aos="fade-up" data-aos-duration="2000">
                    <div type="btn" className="bg-gray-300 cursor-pointer hover:scale-125 transition duration-300 ease-in-out font-black p-3 rounded-full" onClick={() => setShowModal(true)}>
                        <img className='' src={Createicon} alt='' />
                    </div>
                </div>
            </section>

            {showModal && (
                <div
                    className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                    <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 rounded-t">
                                <h4 className='font-bold text-secondary-300 dark:text-secondary-400 text-4xl'>
                                    Create Event
                                </h4>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => { setShowModal(false); resetForm(); }}>
                                    <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative px-6 flex-auto md:grid md:grid-cols-2 gap-3">
                                <div className='div'>
                                    <label className={`block text-sm font-semibold mb-2 ${errors.title ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                        Title*
                                    </label>
                                    <input className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title ? 'border-red-500' : 'border-gray-300'}`} type="text" name="title" value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} required />
                                </div>
                                <div className="div">
                                    <p className='block text-center text-sm font-semibold mb-2'>Switch Button</p>
                                    <div className="toggler">
                                        <input
                                            className='rounded-md text-center focus-visible:outline-none bg-gray-200 dark:bg-gray-800 text-black border-white dark:border-black dark:text-white'
                                            name="statusToggle"
                                            id="statusToggle"  // Ensure this matches `htmlFor`
                                            type="checkbox"
                                            checked={formData.status === 'Active'}  // Set true when status is 'Active'
                                            onChange={handleStatusToggle}
                                        />
                                        <label htmlFor="statusToggle">  {/* Match this to the input id */}
                                            <svg className="toggler-on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                                <polyline className="path check" points="100.2,40.2 51.5,88.8 29.8,67.5"></polyline>
                                            </svg>
                                            <svg className="toggler-off" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                                <line className="path line" x1="34.4" y1="34.4" x2="95.8" y2="95.8"></line>
                                                <line className="path line" x1="95.8" y1="34.4" x2="34.4" y2="95.8"></line>
                                            </svg>
                                        </label>
                                    </div>
                                </div>
                                <div className='div'>
                                    <label className={`block text-sm font-semibold mb-2 ${errors.startdate ? 'text-red-500' : 'text-white'}`} htmlFor="startdate">
                                        Start Date*
                                    </label>
                                    <input className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.startdate ? 'border-red-500' : 'border-gray-300'}`} type="date" name="startdate" value={formData.startdate} onChange={(e) => setFormData({ ...formData, startdate: e.target.value })} required />
                                </div>
                                <div className="div">
                                    <label className={`block text-sm font-semibold mb-2 ${errors.endingdate ? 'text-red-500' : 'text-white'}`} htmlFor="endingdate">
                                        Ending Date*
                                    </label>
                                    <input className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.endingdate ? 'border-red-500' : 'border-gray-300'}`} type="date" name="endingdate" value={formData.endingdate} onChange={(e) => setFormData({ ...formData, endingdate: e.target.value })} required />
                                </div>
                                <div className="div">
                                    <label className={`block text-sm font-semibold mb-2 ${errors.startTime ? 'text-red-500' : 'text-white'}`} htmlFor="startTime">
                                        Start Time*
                                    </label>
                                    <input className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.startTime ? 'border-red-500' : 'border-gray-300'}`} type="time" name="startTime" value={formData.startTime} onChange={(e) => setFormData({ ...formData, startTime: e.target.value })} required />
                                </div>
                                <div className="div">
                                    <label className={`block text-sm font-semibold mb-2 ${errors.endingTime ? 'text-red-500' : 'text-white'}`} htmlFor="endingTime">
                                        Ending Time*
                                    </label>
                                    <input className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.endingTime ? 'border-red-500' : 'border-gray-300'}`} type="time" name="endingTime" value={formData.endingTime} onChange={(e) => setFormData({ ...formData, endingTime: e.target.value })} required />
                                </div>
                            </div>
                            <div className='div px-6'>
                                <label className={`block text-sm font-semibold my-2 ${errors.canvas ? 'text-red-500' : 'text-white'}`} htmlFor="canvas">
                                    Canvas* - <Link to="https://codepen.io/tag/canvas-animation" target="_blank">Check some <span className='canvas001'>Canvas</span></Link>
                                </label>
                                <textarea className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.canvas ? 'border-red-500' : 'border-gray-300'}`} name="canvas" value={formData.canvas} onChange={(e) => setFormData({ ...formData, canvas: e.target.value })} required />
                            </div>
                            <div className="flex justify-center my-5">
                                <button onClick={() => { setButtonLoader(true); handleCreate(); }} className="btn-submit btn text-center text-white rounded">
                                    {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )};

            {selectedevent && editModal && (
                <div
                    className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                    <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 rounded-t">
                                <h4 className='font-bold text-secondary-300 dark:text-secondary-400 text-4xl'>
                                    Edit
                                </h4>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => { setEditModal(false); resetForm(); }}>
                                    <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative px-6 flex-auto md:grid md:grid-cols-2 gap-3">
                                <div className='div'>
                                    <label className={`block text-sm font-semibold mb-2 ${errors.title ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                        Title*
                                    </label>
                                    <input className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title ? 'border-red-500' : 'border-gray-300'}`} type="text" name="title" value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} required />
                                </div>
                                <div className="div">
                                    <p className='block text-center text-sm font-semibold mb-2'>Switch Button</p>
                                    <div className="toggler">
                                        <input
                                            className='rounded-md text-center focus-visible:outline-none bg-gray-200 dark:bg-gray-800 text-black border-white dark:border-black dark:text-white'
                                            name="statusToggle"
                                            id="statusToggle"  // Ensure this matches `htmlFor`
                                            type="checkbox"
                                            checked={formData.status === 'Active'}  // Set true when status is 'Active'
                                            onChange={handleStatusToggle}
                                        />
                                        <label htmlFor="statusToggle">  {/* Match this to the input id */}
                                            <svg className="toggler-on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                                <polyline className="path check" points="100.2,40.2 51.5,88.8 29.8,67.5"></polyline>
                                            </svg>
                                            <svg className="toggler-off" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                                <line className="path line" x1="34.4" y1="34.4" x2="95.8" y2="95.8"></line>
                                                <line className="path line" x1="95.8" y1="34.4" x2="34.4" y2="95.8"></line>
                                            </svg>
                                        </label>
                                    </div>
                                </div>
                                <div className='div'>
                                    <label className={`block text-sm font-semibold mb-2 ${errors.startdate ? 'text-red-500' : 'text-white'}`} htmlFor="startdate">
                                        Start Date*
                                    </label>
                                    <input className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.startdate ? 'border-red-500' : 'border-gray-300'}`} type="date" name="startdate" value={new Date(formData.startdate).toISOString().split('T')[0]} onChange={(e) => setFormData({ ...formData, startdate: e.target.value })} required />
                                </div>
                                <div className="div">
                                    <label className={`block text-sm font-semibold mb-2 ${errors.endingdate ? 'text-red-500' : 'text-white'}`} htmlFor="endingdate">
                                        Ending Date*
                                    </label>
                                    <input className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.endingdate ? 'border-red-500' : 'border-gray-300'}`} type="date" name="endingdate" value={new Date(formData.endingdate).toISOString().split('T')[0]} onChange={(e) => setFormData({ ...formData, endingdate: e.target.value })} required />
                                </div>
                                <div className="div">
                                    <label className={`block text-sm font-semibold mb-2 ${errors.startTime ? 'text-red-500' : 'text-white'}`} htmlFor="startTime">
                                        Start Time*
                                    </label>
                                    <input className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.startTime ? 'border-red-500' : 'border-gray-300'}`} type="time" name="startTime" value={formData.startTime} onChange={(e) => setFormData({ ...formData, startTime: e.target.value })} required />
                                </div>
                                <div className="div">
                                    <label className={`block text-sm font-semibold mb-2 ${errors.endingTime ? 'text-red-500' : 'text-white'}`} htmlFor="endingTime">
                                        Ending Time*
                                    </label>
                                    <input className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.endingTime ? 'border-red-500' : 'border-gray-300'}`} type="time" name="endingTime" value={formData.endingTime} onChange={(e) => setFormData({ ...formData, endingTime: e.target.value })} required />
                                </div>
                            </div>
                            <div className='div px-6'>
                                <label className={`block text-sm font-semibold my-2 ${errors.canvas ? 'text-red-500' : 'text-white'}`} htmlFor="canvas">
                                    Canvas*
                                </label>
                                <textarea className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.canvas ? 'border-red-500' : 'border-gray-300'}`} name="canvas" value={formData.canvas} onChange={(e) => setFormData({ ...formData, canvas: e.target.value })} required />
                            </div>
                            <div className="flex justify-center my-5">
                                <button onClick={() => { setButtonLoader(true); handleSubmitEdit(); }} className="btn-submit btn text-center text-white rounded">
                                    {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )};
        </>
    );
};

export default Events;
