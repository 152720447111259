import React, { useState, useEffect } from 'react';
import { BsFillPencilFill, BsPlusLg } from "react-icons/bs";
import PulseLoader from "react-spinners/PulseLoader";
import { FaTrash } from "react-icons/fa";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import Loadimg from './loader';
import axios from 'axios';

const REACT_APP_HIREING = process.env.REACT_APP_HIREING;
const REACT_APP_CATEGORY_OPPORTUNITIES = process.env.REACT_APP_CATEGORY_OPPORTUNITIES;

const Hireing = () => {
    const [showModal, setShowModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [buttonloader, setButtonLoader] = useState(false);
    const [hireing, setHireing] = useState([]);
    const [opportunitiescategory, setOpportunitiesCategory] = useState([]);
    const [selectedHireingId, setSelectedHireingId] = useState(null);

    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        s_no: '',
        category: '',
        heading: '',
        job_description: '',
        experience_skills: '',
        qualifications: '',
        workingplace: ''
    });

    const fetchData = async () => {
        try {
            const res = await axios.get(`${REACT_APP_HIREING}all`);
            setHireing(res.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const fetchopportunities = async () => {
        try {
            const response = await axios.get(`${REACT_APP_CATEGORY_OPPORTUNITIES}all`);
            setOpportunitiesCategory(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const getNextSerialNumber = () => {
        const existingSerialNumbers = hireing.map(item => item.s_no);
        const maxSerialNumber = Math.max(...existingSerialNumbers);
        return maxSerialNumber !== -Infinity ? maxSerialNumber + 1 : 1;
    };

    useEffect(() => {
        if (!loading) {
            const nextSerialNumber = getNextSerialNumber();
            setFormData(prevState => ({
                ...prevState,
                s_no: nextSerialNumber
            }));
        }
    }, [loading]);

    // Function to reset form data
    const handleInputChange = (e) => {
        const { value } = e.target;
        setFormData({ ...formData, value });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async () => {
        let hasError = false;
        const newErrors = {};
        ['s_no', 'heading', 'job_description', 'experience_skills', 'qualifications',
            'workingplace'].forEach((field) => {
                if (!formData[field]) {
                    newErrors[field] = true;
                    hasError = true;
                }
            });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }
        setButtonLoader(true);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.post(`${REACT_APP_HIREING}`, formData, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            }
            );
            if (response.status === 200) {
                Swal.fire({
                    title: "New Job Post Added",
                    icon: "success",
                    timer: 1500
                });
                setFormData({
                    s_no: '', category: '', heading: '', job_description: '', experience_skills: '', qualifications: '',
                    workingplace: ''
                });
                setShowModal(false);
                fetchData();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh!Failed to Add new Postion.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error",
                timer: 1500
            });
        } finally {
            setButtonLoader(false); // Hide BeatLoader
        }
    };

    const handleEditing = (id) => {
        const selectedData = hireing.find(item => item._id === id);
        setSelectedHireingId(selectedData);
        setFormData(prevState => ({
            ...prevState,
            s_no: selectedData.s_no,
            category: selectedData.category,
            heading: selectedData.heading,
            job_description: selectedData.job_description,
            experience_skills: selectedData.experience_skills,
            qualifications: selectedData.qualifications,
            workingplace: selectedData.workingplace
        }));
        setEditModal(true);
    };

    const handleEdit = async () => {
        let hasError = false;
        const newErrors = {};
        ['s_no', 'heading', 'job_description', 'experience_skills', 'qualifications',
            'workingplace'].forEach((field) => {
                if (!formData[field]) {
                    newErrors[field] = true;
                    hasError = true;
                }
            });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }
        setButtonLoader(true);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_HIREING}${selectedHireingId._id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (response.status === 201) {
                Swal.fire({
                    title: "Hiring Post Update Successfully",
                    icon: "success",
                    timer: 1500
                });
                setFormData({
                    category: '', heading: '', job_description: '', experience_skills: '', qualifications: '',
                    workingplace: ''
                });
                setEditModal(false);
                fetchData();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({
                title: "Ohh! Failed to Update Hireing Post.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error",
                timer: 1500
            });
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    };

    const handleDelete = (id) => {
        confirmDelete(id);
    };

    const confirmDelete = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCloseButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
            timer: 1500,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const refreshToken = Cookies.get('refreshToken');
                    const response = await axios.delete(`${REACT_APP_HIREING}${id}`, {
                        headers: {
                            'Authorization': `Bearer ${refreshToken}`
                        }
                    });
                    if (response.status === 200) {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success",
                            timer: 1500
                        });
                    }
                    fetchData();
                    window.location.reload()
                } catch (error) {
                    console.error('Error While deleteing:', error);
                    Swal.fire({
                        title: "Error!",
                        text: error.response?.data?.message || error.message || "An error occurred",
                        icon: "error",
                        timer: 1500
                    });
                }
            }
        })
    };

    const resetForm = () => {
        const nextSerialNumber = getNextSerialNumber();
        setFormData({
            s_no: nextSerialNumber, category: '', heading: '', job_description: '', experience_skills: '', qualifications: '', workingplace: ''
        });
        setErrors({});
    };

    useEffect(() => {
        fetchData();
        fetchopportunities();
        getNextSerialNumber();
    }, []);

    return (
        <>
            <div>
                {loading ? (
                    <Loadimg
                    />
                ) : (
                    <div>
                        {/* //-------------------------- Add New Post button--------------------// */}
                        <div className="relative pb-[100px]">
                            <div type="btn" onClick={() => setShowModal(true)} className="absolute cursor-pointer hover:shadow-inner shadow-sm btn-secondary flex  text-white right-0 mt-[15px] gap-2"><span className=''><BsPlusLg /></span>Add Hiring
                            </div>
                        </div>
                        {/* //-------------------Add New Post button end here----------------// */}
                        {/* //------Adding mape method for geting all data form backend--------// */}
                        <section className="hireing-sec w-full text-gray-500 dark:text-gray-400">
                            <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 ">
                                {hireing.map((item) => (
                                    <div key={item._id} className="group cursor-pointer overflow-hidden bg-[transparent] border dark:border-secondary-50 rounded-3xl shadow-sm py-2">
                                        <div className="group relative  p-4">
                                            {opportunitiescategory.filter(catItem => catItem.s_no === parseInt(item.category)).map(filteredItem => (
                                                <h5 key={filteredItem.s_no} className="mb-2 text-2xl font-semibold tracking-tight dark:text-white text-gray-900">{filteredItem.name}</h5>
                                            ))}
                                            <p className="mb-3 text-xl font-semibold text-gray-700 dark:text-white">
                                                {item.heading}
                                            </p>
                                            <button type="button" onClick={() => handleEditing(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="font-medium z-10 flex transition-all duration-300 absolute bg-[white] rounded-full p-2 top-1 right-2 text-blue-600 hover:scale-110 hover:underline">
                                                <BsFillPencilFill />
                                            </button>
                                            <ul className="absolute inset-x-0 -bottom-full flex items-center justify-center gap-12 pb-2 transition-all duration-300 group-hover:-inset-2 group-hover:bg-gradient-to-t group-hover:from-white/70">
                                                <li className='flex justify-center w-2 h-2'>
                                                    <button type="button" onClick={() => handleDelete(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="flex absolute transition-all duration-300 font-medium bottom-0 text-[black] bg-[white] rounded-full p-2 hover:scale-110 hover:underline mb-2">
                                                        <FaTrash />
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                        {/* //------mape method for geting all data form backend end Here--------// */}
                    </div>
                )}
            </div>
            {
                showModal && (
                    <div
                        className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 rounded-t">
                                    <h4 className='font-bold text-secondary-300 dark:text-secondary-400 text-4xl'>
                                        Add Hiring
                                    </h4>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setShowModal(false); resetForm(); }}
                                    >
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative px-6 flex-auto">
                                    <div className='grid grid-cols-2 gap-2 mb-1'>
                                        <div className="">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                                Serial No*
                                            </label>
                                            <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" value={formData.s_no} onChange={(e) => setFormData({ ...formData, s_no: e.target.value })} required />
                                        </div>
                                        <div className="">
                                            <label className='block text-sm font-semibold mb-2 text-white'>Category*</label>
                                            <div className='w-full border-2 justify-between text-[black] bg-[white] font-medium rounded py-2 text-sm inline-flex items-center'>
                                                <select
                                                    className='w-full dark:bg-[transparent] focus-visible:outline-none'
                                                    name="category"
                                                    id="category"
                                                    value={formData.category}
                                                    onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                                                >
                                                    {opportunitiescategory.map((item) => (
                                                        <option key={item.s_no} value={item.s_no}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <label className={`block text-sm font-semibold mb-2  ${errors.heading ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                        Position*
                                    </label>
                                    <textarea className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.heading ? 'border-red-500' : 'border-gray-300'}`} type="text" name="heading" value={formData.heading} onChange={(e) => setFormData({ ...formData, heading: e.target.value })} placeholder='Postion' required />
                                    <label className={`block text-sm font-semibold mb-2 ${errors.job_description ? 'text-red-500' : 'text-white'}`}>
                                        Job Description*
                                    </label>
                                    <textarea className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.job_description ? 'border-red-500' : 'border-gray-300'}`} type="text" name="job_description" value={formData.job_description} onChange={(e) => setFormData({ ...formData, job_description: e.target.value })} placeholder='Job Description' required />
                                    <label className={`block text-sm font-semibold mb-2 ${errors.experience_skills ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                        Experience/Skills*
                                    </label>
                                    <textarea className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.experience_skills ? 'border-red-500' : 'border-gray-300'}`} type="text" name="experience_skills" value={formData.experience_skills} onChange={(e) => setFormData({ ...formData, experience_skills: e.target.value })} placeholder='Experience/Skills' required />
                                    <label className={`block text-sm font-semibold mb-2 ${errors.qualifications ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                        Qualifications*
                                    </label>
                                    <textarea className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.qualifications ? 'border-red-500' : 'border-gray-300'}`} type="text" name="qualifications" value={formData.qualifications} onChange={(e) => setFormData({ ...formData, qualifications: e.target.value })} placeholder='Qualifications' required />
                                    <label className={`block text-sm font-semibold mb-2 ${errors.workingplace ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                        Working Place*
                                    </label>
                                    <textarea className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.workingplace ? 'border-red-500' : 'border-gray-300'}`} type="text" name="workingplace" value={formData.workingplace} onChange={(e) => setFormData({ ...formData, workingplace: e.target.value })} placeholder='Add your work place' required />
                                </div>
                                <div className="flex justify-center my-5">
                                    <button onClick={() => { setButtonLoader(true); handleSubmit() }} className="btn-submit btn text-center text-white rounded">
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div >
                )
            }
            {
                selectedHireingId && editModal && (
                    <div
                        className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 rounded-t">
                                    <h4 className='font-bold text-secondary-300 dark:text-secondary-400 text-4xl'>
                                        Edit
                                    </h4>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setEditModal(false); resetForm(); }}>
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative px-6 flex-auto">
                                    <div className='grid grid-cols-2 gap-2 mb-1'>
                                        <div className="">
                                            <label lassName={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                                Serial No*
                                            </label>
                                            <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" value={formData.s_no} onChange={(e) => setFormData({ ...formData, s_no: e.target.value })} required />
                                        </div>
                                        <div className="">
                                            <label className='block text-sm font-semibold mb-1 text-white'>Category*</label>
                                            <div className='w-full border-2 justify-between border-gray-300 text-[black] bg-[white] font-medium rounded py-2 text-sm inline-flex items-center'>
                                                <select
                                                    className='w-full dark:bg-[transparent] focus-visible:outline-none'
                                                    name="category"
                                                    id="category"
                                                    value={formData.category}
                                                    onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                                                >
                                                    {opportunitiescategory.map((item) => (
                                                        <option key={item.s_no} value={item.s_no}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <label className={`block text-sm font-semibold mb-2 ${errors.heading ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                        Position*
                                    </label>
                                    <textarea className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.heading ? 'border-red-500' : 'border-gray-300'}`} type="text" name="heading" value={formData.heading} onChange={(e) => setFormData({ ...formData, heading: e.target.value })} placeholder='Postion' required />
                                    <label className={`block text-sm font-semibold mb-2 ${errors.job_description ? 'text-red-500' : 'text-white'}`}>
                                        Job Description*
                                    </label>
                                    <textarea className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.job_description ? 'border-red-500' : 'border-gray-300'}`} type="text" name="job_description" value={formData.job_description} onChange={(e) => setFormData({ ...formData, job_description: e.target.value })} placeholder='Job Description' required />
                                    <label className={`block text-sm font-semibold mb-2 ${errors.experience_skills ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                        Experience/Skills*
                                    </label>
                                    <textarea className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.experience_skills ? 'border-red-500' : 'border-gray-300'}`} type="text" name="experience_skills" value={formData.experience_skills} onChange={(e) => setFormData({ ...formData, experience_skills: e.target.value })} placeholder='Experience/Skills' required />
                                    <label className={`block text-sm font-semibold mb-2 ${errors.qualifications ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                        Qualifications*
                                    </label>
                                    <textarea className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.qualifications ? 'border-red-500' : 'border-gray-300'}`} type="text" name="qualifications" value={formData.qualifications} onChange={(e) => setFormData({ ...formData, qualifications: e.target.value })} placeholder='Qualifications' required />
                                    <label className={`block text-sm font-semibold mb-2 ${errors.workingplace ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                        Working Place*
                                    </label>
                                    <textarea className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.workingplace ? 'border-red-500' : 'border-gray-300'}`} type="text" name="workingplace" value={formData.workingplace} onChange={(e) => setFormData({ ...formData, workingplace: e.target.value })} placeholder='Add your work place' required />
                                </div>
                                <div className=" flex justify-center my-5">
                                    <button onClick={() => { setButtonLoader(true); handleEdit() }} className="btn-submit btn text-center text-white rounded">
                                        {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default Hireing;