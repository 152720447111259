import React, { useState, useEffect } from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import { BsFillPencilFill, BsPlusLg } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import Loadimg from './loader';
import axios from 'axios';

const REACT_APP_TESTIMONIAL = process.env.REACT_APP_TESTIMONIAL;

const Testimonial = () => {

    const [showModal, setShowModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [buttonloader, setButtonLoader] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedTestimonialId, setSelectedTestimonialId] = useState(null);
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        s_no: "",
        title: "",
        feedback: ""
    });

    const fetchData = async () => {
        try {
            const response = await axios.get(`${REACT_APP_TESTIMONIAL}all`);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const getNextSerialNumber = () => {
        const existingSerialNumbers = data.map(item => item.s_no);
        const maxSerialNumber = Math.max(...existingSerialNumbers);
        return maxSerialNumber !== -Infinity ? maxSerialNumber + 1 : 1;
    };

    // Function to reset form data
    const handleInputChange = (e) => {
        const { value } = e.target;
        setFormData({ ...formData, value });
    };

    const resetForm = () => {
        const nextSerialNumber = getNextSerialNumber();
        setFormData({ s_no: nextSerialNumber, title: '', feedback: '' });
        setErrors({});
    };

    useEffect(() => {
        if (!loading) {
            const nextSerialNumber = getNextSerialNumber();
            setFormData(prevState => ({
                ...prevState,
                s_no: nextSerialNumber
            }));
        }
    }, [loading]);

    useEffect(() => {
        fetchData();
    }, []);

    const handleCreate = async () => {
        let hasError = false;
        const newErrors = {};
        ['s_no', 'title', 'feedback'].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }
        setButtonLoader(true);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.post(`${REACT_APP_TESTIMONIAL}`, formData,{
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });

            if (response.status === 201) {
               Swal.fire({timer: 1500,
                    title: "Testimonial has been added successfully.",
                    icon: "success"
                });
                setFormData({
                    s_no: "",
                    title: "",
                    feedback: ""
                })
                fetchData();
                setShowModal(false);
                window.location.reload();
            } else {
                alert('Failed to upload data.');
            }
            fetchData();
            window.location.reload();
        } catch (error) {
           Swal.fire({timer: 1500,
                title: "Ohh!Failed to Add Testimonial.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false); // Hide BeatLoader
        }
    };

    const handleEditing = (id) => {
        const selectedData = data.find(item => item._id === id);
        setSelectedTestimonialId(selectedData); // Set the selected FAQ ID
        setFormData(prevState => ({
            ...prevState,
            s_no: selectedData.s_no,
            title: selectedData.title,
            feedback: selectedData.feedback,
        }));
        setEditModal(true)
    };

    const handleEdit = async () => {
        let hasError = false;
        const newErrors = {};
        ['s_no', 'title', 'feedback'].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return; // Prevent submission if required fields are empty
        }
        setButtonLoader(true);
        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_TESTIMONIAL}${selectedTestimonialId._id}`, formData,{
                headers:{
                    'Authorization': `Bearer ${refreshToken}`
                }
            });

            if (response.status === 200) {
               Swal.fire({timer: 1500,
                    title: "testimonial updated successfully.",
                    icon: "success"
                });
                setFormData({
                    s_no: "",
                    title: "",
                    feedback: "",
                })
                setEditModal(false);
                fetchData();
                window.location.reload();
            }
        } catch (error) {
           Swal.fire({timer: 1500,
                title: "Ohh! Failed to Update testimonial.",
                text: error.response?.data?.message || error.message || "An error occurred",
                icon: "error"
            });
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    };

    const handleDelete = (id) => {
        confirmDelete(id);
    };

    const confirmDelete = async (id) => {
       Swal.fire({timer: 1500,
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const refreshToken = Cookies.get('refreshToken');
                    const response = await axios.delete(`${REACT_APP_TESTIMONIAL}${id}`, {
                        headers: {
                            'Authorization': `Bearer ${refreshToken}`
                        }
                    });
                    if (response.status === 200) {
                       Swal.fire({timer: 1500,
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success"
                        });
                        fetchData();
                        window.location.reload()
                    }
                } catch (error) {
                    console.error('Error While deleteing:', error);
                   Swal.fire({timer: 1500,
                        title: "Error!",
                        text: error.response?.data?.message || error.message || "An error occurred",
                        icon: "error"
                    });
                }
            }
        })
    };

    return (
        <>
            {loading ? (
                <Loadimg
                />
            ) : (
                <>
                    <div className="relative pb-[100px]">
                        <div type="btn" onClick={() => setShowModal(true)} className="absolute cursor-pointer hover:shadow-inner shadow-sm btn-secondary flex text-white right-0 mt-[15px] gap-2"><span className=''><BsPlusLg /></span>Add Testimonial
                        </div>
                    </div>
                    <section className='testimonial-sec'>
                        <div className='grid gap-8 grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 relative'>
                            {data.map((item) => (
                                <div key={item._id} className="group cursor-pointer border shadow-md overflow-hidden rounded-3xl dark:text-gray-400 dark:border-secondary-50" data-aos="zoom-in" data-aos-duration="1000">
                                    <p className="pl-5 pr-10 text-xl font-semibold mt-4 text-gray-900 dark:text-white">
                                        {item.feedback}
                                    </p>
                                    <div className='w-2 h-2'>
                                        <button type='button' onClick={() => handleEditing(item._id)}
                                            className="font-medium z-10 flex absolute bg-[white] rounded-full p-2 top-2 right-2 text-blue-600 transition-all duration-300 dark:text-blue-500 hover:scale-125 hover:underline">
                                            <BsFillPencilFill />
                                        </button>
                                    </div>
                                    <div className="pl-5 py-4 font-semibold text-gray-900 dark:text-gray-600">
                                        {item.title}
                                    </div>
                                    <ul className="absolute inset-x-0 -bottom-full flex items-center justify-center gap-10 pb-5 transition-all duration-300 group-hover:inset-y-0 group-hover:bg-gradient-to-t group-hover:from-white/70">
                                        <li className='w-2 h-2'>
                                            <button type="button" onClick={() => handleDelete(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="flex absolute font-medium transition-all duration-300 bottom-6 text-[black] bg-[white] rounded-full p-2 hover:scale-125 hover:underline">
                                                <FaTrash />
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </section>
                </ >
            )}
            {
                showModal ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none p-5">
                                {/*header*/}
                                <div className="flex items-start justify-between rounded-t">
                                    <h4 className='pb-2 font-bold text-secondary-500 text-4xl'>Add Testimonial</h4>
                                    <button className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setShowModal(false); resetForm(); }}>
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                <div className="relative flex-auto">
                                    <div className="mb-4">
                                        <label lassName={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                            Serial No*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" value={formData.s_no} onChange={(e) => setFormData({ ...formData, s_no: e.target.value })} required />
                                    </div>
                                    <div className="mb-4">
                                        <label className={`block text-black text-sm font-normal mb-2 ${errors.title ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                            Title*
                                        </label>
                                        <input className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title ? 'border-red-500' : 'border-gray-300'}`} id="title" type="text" value={formData.title}
                                            onChange={(e) => setFormData({ ...formData, title: e.target.value })} placeholder="Name" />
                                    </div>
                                    <div className="mb-4">
                                        <label className={`block text-black text-sm font-normal mb-2 ${errors.feedback ? 'text-red-500' : 'text-white'}`} htmlFor="feedback">
                                            Feedback*
                                        </label>
                                        <textarea className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.feedback ? 'border-red-500' : 'border-gray-300'}`} id="feedback" value={formData.feedback}
                                            onChange={(e) => setFormData({ ...formData, feedback: e.target.value })} placeholder="Feedback" />
                                    </div>
                                    <div className="flex justify-center">
                                        <button onClick={() => { setButtonLoader(true); handleCreate() }} className="btn-submit btn text-center text-white p-5 rounded">
                                            {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
            {
                selectedTestimonialId && editModal ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-2xl">
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none p-5">
                                <div className="flex items-start justify-between rounded-t">
                                    <h4 className='pb-2 font-bold text-secondary-500 text-4xl'>Edit</h4>
                                    <button className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setEditModal(false); resetForm(); }} >
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                <div className="relative flex-auto">
                                    <div className="mb-4">
                                        <label lassName={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`}>
                                            Serial No*
                                        </label>
                                        <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`} type="number" name="s_no" value={formData.s_no} onChange={(e) => setFormData({ ...formData, s_no: e.target.value })} required />
                                    </div>
                                    <div className="mb-4">
                                        <label className={`block text-black text-sm font-normal mb-2 ${errors.title ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                                            Title*
                                        </label>
                                        <input className={`shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title ? 'border-red-500' : 'border-gray-300'}`} id="title" type="text" value={formData.title}
                                            onChange={(e) => setFormData({ ...formData, title: e.target.value })} placeholder="Name" />
                                    </div>
                                    <div className="mb-4">
                                        <label className={`block text-black text-sm font-normal mb-2 ${errors.feedback ? 'text-red-500' : 'text-white'}`} htmlFor="feedback">
                                            Feedback*
                                        </label>
                                        <textarea className={`shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.feedback ? 'border-red-500' : 'border-gray-300'}`} id="feedback" value={formData.feedback}
                                            onChange={(e) => setFormData({ ...formData, feedback: e.target.value })} placeholder="Feedback" />
                                    </div>
                                    <div className=" flex justify-center">
                                        <button onClick={() => { setButtonLoader(true); handleEdit() }} className="btn-submit btn text-center text-white p-5 rounded">
                                            {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </ >
    );
};

export default Testimonial;
